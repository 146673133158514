import Container from "react-bootstrap/Container";
import BootstrapNavbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { useTranslation } from "react-i18next";
import * as urls from "../constants/urls";

export const Footer = () => {
  const { t } = useTranslation();
  return (
    <BootstrapNavbar collapseOnSelect expand="md" bg="secondary" variant="dark">
      <Container className="px-4" fluid>
        <BootstrapNavbar.Toggle aria-controls="responsive-navbar-nav" />
        <BootstrapNavbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            {urls.TOS ? (
              <Nav.Link href={urls.TOS}>{t("footer.tos")}</Nav.Link>
            ) : null}
            {urls.PRIVACY_POLICY ? (
              <Nav.Link href={urls.PRIVACY_POLICY}>
                {t("footer.privacy")}
              </Nav.Link>
            ) : null}
          </Nav>
        </BootstrapNavbar.Collapse>
      </Container>
    </BootstrapNavbar>
  );
};
