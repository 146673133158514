export const LOGIN = "/login";
export const REGISTER = "/register";
export const FORGOT_PASSWORD = "/forgot-password";
export const RESET_PASSWORD = "/reset-password";
export const EMAIL_VERIFICATION_SENT = "/verification-email-sent";
export const VERIFY_USER = "/verify-user";
export const USER_CHECKLISTS = "/lists";
export const USER_ARCHIVED_CHECKLISTS = "/archived-lists";
export const TEMPLATES = "/templates";
export const REPORTS = "/reports";
export const REPORTS_OVERVIEW = "/reports/overview";
export const REPORTS_TRENDS = "/reports/trends";
export const REPORTS_COMPARE = "/reports/compare";
export const HOME_PAGE = USER_CHECKLISTS;
export const TOS = process.env.REACT_APP_TOS_LINK ?? "";
export const PRIVACY_POLICY = process.env.REACT_APP_PRIVACY_LINK ?? "";
export const MATOMO_TAG_MANAGER_CONTAINER_URL =
  process.env.REACT_APP_MATOMO_TAG_MANAGER_CONTAINER_URL ?? "";
