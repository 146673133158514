import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { useTranslation } from "react-i18next";
import { CompareReportTable, UserTaskResponse } from "../../types/Report";
import { Body } from "../Body";
import { MiniAvatar } from "../MiniAvatar";

const NoAnswer = ({ text }: { text: string }) => {
  return (
    <small className="rounded-pill text-info fw-normal px-2 py-1 border border-1 border-info">
      {text}
    </small>
  );
};

const MarkdownCell = ({ text }: { text: string }) => {
  return (
    <span className="text-start markdown-table-cell">
      <Body text={text} />
    </span>
  );
};

const CheckboxCell = ({ text }: { text: string }) => {
  return (
    <small className="bg-light-slate rounded-pill text-black fw-normal px-2 py-1 border border-1">
      {text}
    </small>
  );
};

const AnswerCell = ({
  value,
  interfaceType,
}: {
  value?: string;
  interfaceType: string;
}) => {
  const { t } = useTranslation();
  if (!value || value.trim() === "") {
    let text = t("reports.compare.noAnswer");
    if (interfaceType === "multi_choice" && value === null) {
      text = t("reports.compare.noneSelected");
    }
    return <NoAnswer text={text} />;
  }

  if (interfaceType === "numeric") {
    return <span className="fw-normal fs-7">{value}</span>;
  }

  if (interfaceType === "linear_scale_rating") {
    return <p className="mb-0 fw-normal first-letter-bold fs-7">{value}</p>;
  }

  if (interfaceType === "markdown_textarea") {
    return <MarkdownCell text={value} />;
  }

  if (interfaceType === "checkbox") {
    return <CheckboxCell text={value} />;
  }

  return <span>{value}</span>;
};

const AnswerRow = ({
  username,
  task,
  dates,
  interfaceType,
}: {
  username: string;
  task: UserTaskResponse;
  dates: string[];
  interfaceType: string;
}) => {
  const [clippedClass, setClippedClass] = useState("table-row-clipped");
  return (
    <div
      className={`${
        interfaceType === "markdown_textarea" ? clippedClass : ""
      } p-0 m-0 border-0`}
      onClick={() => setClippedClass("")}
    >
      <tr>
        <td className="w-25 text-start p-lg-4">
          <div className="d-flex">
            <MiniAvatar
              className="me-2"
              user={{ username: username, display_name: task.assignee_name }}
            />
            <span
              title={username}
              className="d-inline-block text-truncate w-90"
            >
              {task.assignee_name}
            </span>
          </div>
        </td>
        {dates.map((date) => (
          <td className="w-25 p-lg-4" key={date}>
            <AnswerCell interfaceType={interfaceType} value={task[date]} />
          </td>
        ))}
      </tr>
    </div>
  );
};

const AnswerTable = ({ data }: { data?: CompareReportTable }) => {
  const { t } = useTranslation();
  const [startIndex, setStartIndex] = useState(0);
  const [datesInView, setDatesInView] = useState<string[]>([]);
  const columnsToDisplay = 3;

  useEffect(() => {
    data &&
      setDatesInView(
        data.dates.slice(startIndex, startIndex + columnsToDisplay)
      );
  }, [startIndex, data]);

  if (!data) {
    return <></>;
  }

  const increment = () => {
    setStartIndex((currIndex) => {
      if (currIndex < data.dates.length - columnsToDisplay) {
        return currIndex + 1;
      }
      return currIndex;
    });
  };

  const decrement = () => {
    setStartIndex((currIndex) => {
      if (currIndex > 0) {
        return currIndex - 1;
      }
      return currIndex;
    });
  };

  return (
    <Table bordered responsive className="table-fixed w-100">
      <thead>
        <tr>
          <th className="w-25 fw-normal">{t("reports.compare.teamMember")}</th>
          {datesInView.map((date, index) => (
            <th className="w-25 text-start py-0 my-0" key={date}>
              <div className="d-flex pb-2 flex-row fw-normal flex-nowrap align-items-center">
                {index === 0 && startIndex !== 0 && (
                  <Button
                    className="bg-transparent border-0 py-0 my-0 px-2 fs-6 lh-1"
                    onClick={decrement}
                  >
                    {"<"}
                  </Button>
                )}
                <span className="flex-grow-1 text-center">{date}</span>
                {index === datesInView.length - 1 &&
                  startIndex < data.dates.length - columnsToDisplay && (
                    <Button
                      className="bg-transparent border-0 py-0 my-0 px-2 fs-6 lh-1"
                      onClick={increment}
                    >
                      {">"}
                    </Button>
                  )}
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <div className="scollable-body">
          {Object.keys(data.tasks).map((username) => (
            <AnswerRow
              username={username}
              key={username}
              task={data.tasks[username]}
              dates={datesInView}
              interfaceType={data.interface_type}
            />
          ))}
        </div>
      </tbody>
    </Table>
  );
};

export default AnswerTable;
