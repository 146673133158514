import { SingleController } from "@opencraft/providence/base/singles/types/SingleController";
import { ListController } from "@opencraft/providence/base/lists/types/ListController";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { CustomAlert } from "../components/Alerts";

declare interface ErrorAlertArgs {
  controllers: Array<SingleController<any> | ListController<any>>;
}

export const ErrorAlerts = ({ controllers }: ErrorAlertArgs) => {
  const errors: string[] = [];
  controllers.map((controller) => errors.push(...controller.errors.messages));
  return (
    <Row>
      {errors.map((message) => (
        <Col xs={12} key={message}>
          <CustomAlert className="pb-0" variant="danger">
            <p>{message}</p>
          </CustomAlert>
        </Col>
      ))}
    </Row>
  );
};
