import BootstrapButton, {
  ButtonProps as BaseButtonProps,
} from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { useTranslation } from "react-i18next";

interface ButtonProps extends BaseButtonProps {
  loading?: boolean;
}

export const Button = (props: ButtonProps) => {
  const { t } = useTranslation();
  const { loading, ...buttonProps } = props;
  const loadingStyle = { lineHeight: 0 };
  return (
    <BootstrapButton
      style={loading ? loadingStyle : {}}
      disabled={loading}
      {...buttonProps}
    >
      {loading ? (
        <Spinner
          as="span"
          className="m-0 p-0 spinner-dimensions"
          aria-hidden="true"
          animation="border"
          role="status"
        >
          <span className="visually-hidden">{t("pageStatuses.loading")}</span>
        </Spinner>
      ) : (
        props.children
      )}
    </BootstrapButton>
  );
};
