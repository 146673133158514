import { useList } from "@opencraft/providence-redux/hooks";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { useSearchParams } from "react-router-dom";
import {
  DEFINITION_LIST_API,
  SHORT_DEFINITION_LIST_API,
} from "../../constants/api-urls";
import { OverviewReport } from "../../types/Report";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Stack from "react-bootstrap/Stack";
import { PieChart, Pie, ResponsiveContainer } from "recharts";
import { LoadSection } from "../../components/LoadSection";
import { ReactComponent as SortIcon } from "../../assets/icons/sorting.svg";
import { dateString } from "../../utils/helpers";
import { AssigneesCard } from "../../components/AssigneesCard";
import { useTranslation } from "react-i18next";
import { ReminderOptionsMenu } from "../../components/ReminderOptions";
import { ShortDefinition } from "../../types/Definition";

interface ResponseOverviewRate {
  completed: number;
  pending: number;
}

const ReportOverview = () => {
  const { t } = useTranslation();
  const [currentQueryParameters] = useSearchParams();

  const controller = useList<OverviewReport>("overview-report", {
    paginated: false,
  });
  const [overallRate, setOverallRate] = useState<ResponseOverviewRate>({
    completed: 0,
    pending: 0,
  });
  const shortController = useList<ShortDefinition>("short_definitions_list", {
    paginated: false,
  });
  const [checklistDefName, setChecklistDefName] = useState("");
  useEffect(() => {
    const checklistDefinitionID = currentQueryParameters.get(
      "checklistDefinition"
    );
    shortController.endpoint = `${SHORT_DEFINITION_LIST_API}`;
    shortController.getOnce();
    if (shortController.rawList) {
      const currentChecklistDefinition = shortController.rawList.find(
        (item) => item.id === checklistDefinitionID
      );
      if (currentChecklistDefinition) {
        setChecklistDefName(currentChecklistDefinition.name);
      }
    }
  }, [currentQueryParameters, shortController]);

  useEffect(() => {
    const currentChecklistDefinition = currentQueryParameters.get(
      "checklistDefinition"
    );
    const baseUrl = `${DEFINITION_LIST_API}${currentChecklistDefinition}/overview/`;
    const queryParams = currentQueryParameters.toString();
    if (queryParams && currentChecklistDefinition) {
      controller.endpoint = `${baseUrl}?${queryParams}`;
      controller.get().then((resp) => {
        const completed = resp.reduce(
          (acc, report) => acc + report.completed_checklist_count,
          0
        );
        const total = resp.reduce(
          (acc, report) => acc + report.total_checklist_count,
          0
        );
        const completed_percentage = Math.round((completed / total) * 100);
        setOverallRate({
          completed: completed_percentage,
          pending: 100 - completed_percentage,
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQueryParameters]);

  const ResponsePieChart = () => {
    const data = [
      {
        name: t("reports.overview.responded"),
        value: overallRate.completed,
        fill: "#64ffda",
      },
      {
        name: t("reports.overview.noResponse"),
        value: overallRate.pending,
        fill: "#4f5d76",
      },
    ];
    return (
      <ResponsiveContainer width="100%" height="100%">
        <PieChart className="ps-0">
          <Pie
            data={data}
            dataKey="value"
            stroke="none"
            cx="50%"
            cy="50%"
            outerRadius={100}
            startAngle={90}
            endAngle={-270}
          />
        </PieChart>
      </ResponsiveContainer>
    );
  };

  const OverviewRow = ({ runReport }: { runReport: OverviewReport | null }) => {
    if (!runReport || runReport.total_checklist_count <= 0) {
      return <></>;
    }
    return (
      <Card className="bg-secondary py-3 border-0 rounded-3">
        <Card.Body>
          <Row>
            <Col md={2} className="ps-md-4">
              {dateString(runReport.start_date)}
            </Col>
            <Col md={2}>{runReport.team_name}</Col>
            <Col md={2}>
              {runReport.completed_checklist_count} of{" "}
              {runReport.total_checklist_count}
            </Col>
            <Col md={3}>
              <AssigneesCard
                id={runReport.id}
                assignees={runReport.assignees.filter(
                  (assignee) => assignee.completed
                )}
              />
            </Col>
            <Col md={3}>
              <Row>
                <Col className="d-none d-sm-block pe-0">
                  <AssigneesCard
                    id={runReport.id}
                    assignees={runReport.assignees.filter(
                      (assignee) => !assignee.completed
                    )}
                  />
                </Col>
                <Col className="p-0 pe-1" xs={12} sm={3} md={2} lg={2}>
                  <ReminderOptionsMenu
                    incompleteAssignees={runReport.assignees.filter(
                      (assignee) => !assignee.completed
                    )}
                    checklistDefName={checklistDefName}
                    runID={runReport.id}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  };

  const ResponseOverviewCard = () => {
    return (
      <Card className="bg-secondary py-3 border-0 rounded-3">
        <Card.Body>
          <Container fluid>
            <Row>
              <Col style={{ height: "200px" }} lg={3}>
                <ResponsePieChart />
              </Col>
              <Col
                className="ps-4 pt-lg-0 pt-sm-4 d-flex align-self-center flex-column"
                lg={9}
              >
                <h5 className="mb-3">
                  {t("reports.overview.heroCard.header")}
                </h5>
                <p className="mb-1">
                  <span className="dot bg-primary border border-info me-2" />
                  <span className="fs-6 me-1">
                    {overallRate.completed}%
                  </span>{" "}
                  {t("reports.overview.heroCard.responded")}
                </p>
                <p>
                  <span className="dot bg-light-navy border border-info me-2" />
                  <span className="fs-6 me-1">{overallRate.pending}%</span>{" "}
                  {t("reports.overview.heroCard.didNotRespond")}
                </p>
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
    );
  };

  const OverviewRows = ({ rows }: { rows: OverviewReport[] }) => {
    const [tableRows, setTableRows] = useState(rows);
    const reverseResults = () => {
      setTableRows((curRows) => {
        const revRows = curRows.reverse();
        return [...revRows];
      });
    };

    return (
      <Stack gap={5}>
        <ResponseOverviewCard />
        <Stack className="fs-8" gap={3}>
          <Row className="p-3 py-0 d-none d-md-flex">
            <Col md={2} className="ps-md-4">
              <Button
                onClick={reverseResults}
                className="p-0 m-0 bg-transparent border-0 text-light-slate fs-sm no-hover"
              >
                {t("reports.overview.tableHeaders.dateSent")}
                <SortIcon className="ms-2" />
              </Button>
            </Col>
            <Col md={2}>{t("reports.overview.tableHeaders.teamName")}</Col>
            <Col md={2}>{t("reports.overview.tableHeaders.response")}</Col>
            <Col md={3}>{t("reports.overview.tableHeaders.responded")}</Col>
            <Col md={3}>{t("reports.overview.tableHeaders.didNotRespond")}</Col>
          </Row>
          {tableRows.map((runReport) => (
            <OverviewRow key={runReport.id} runReport={runReport} />
          ))}
        </Stack>
      </Stack>
    );
  };

  return (
    <LoadSection controllers={[controller]}>
      {() => {
        return (
          <>
            {controller.list.length === 0 ? (
              <p className="text-center mt-5 pt-4 text-dark-slate">
                {t("reports.noResultsMsg")}
              </p>
            ) : (
              <OverviewRows rows={controller.rawList} />
            )}
          </>
        );
      }}
    </LoadSection>
  );
};

export default ReportOverview;
