import { createContext, ReactElement, useState } from "react";
import { Message } from "../types/Message";

interface INotificationContext {
  messages?: Array<Message>;
  setNotification: (message: Message) => void;
  clearNotifications: () => void;
}

const NotificationContext = createContext<INotificationContext>(
  {} as INotificationContext
);

declare interface NotificationContextArgs {
  children: ReactElement;
}

export const NotificationProvider = ({ children }: NotificationContextArgs) => {
  const [messages, setMessages] = useState<Array<Message>>([]);
  const setNotification = (message: Message) => {
    setMessages((messages) => {
      return [...messages, message];
    });
  };

  const clearNotifications = () => {
    setMessages([]);
  };

  return (
    <NotificationContext.Provider
      value={{
        messages,
        setNotification,
        clearNotifications,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationContext;
