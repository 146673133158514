import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { LinearScaleRate } from "./LinearScaleRate";
import { MarkdownTextarea } from "./MarkdownTextarea";
import { InterfaceComponentProps } from "../../types/Interaction";
import { NumericInput } from "./NumericInput";
import { MultipleChoice } from "./MultipleChoice";

const INTERFACE_NAME_TO_COMPONENT: {
  [interfaceName: string]: (a: InterfaceComponentProps) => JSX.Element;
} = {
  linear_scale_rating: LinearScaleRate,
  markdown_textarea: MarkdownTextarea,
  numeric: NumericInput,
  multi_choice: MultipleChoice,
};

declare interface InteractionProps {
  interfaceType: string;
  customizationArgs: any;
  response: any;
  required?: boolean;
}

export const Interaction = ({
  interfaceType,
  customizationArgs,
  response,
  required,
}: InteractionProps) => {
  const InterfaceComponent = INTERFACE_NAME_TO_COMPONENT[interfaceType];

  return (
    <>
      <InterfaceComponent
        customizationArgs={customizationArgs}
        response={response}
        required={required}
      ></InterfaceComponent>
      {response.errors?.map((error: any) => (
        <Row key={error} className="mt-2">
          <Col>
            <small className="text-danger">{error}</small>
          </Col>
        </Row>
      ))}
    </>
  );
};
