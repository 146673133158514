import {
  BarChart,
  Bar,
  XAxis,
  ResponsiveContainer,
  YAxis,
  LabelList,
} from "recharts";
import { InterfaceStats, RatingBucket } from "../../types/Report";
import { useTranslation } from "react-i18next";

export interface RatingBarChartArgs {
  interfaceStats?: InterfaceStats;
  minValueDescription?: string;
  maxValueDescription?: string;
}

const RatingBarChart = ({
  interfaceStats,
  minValueDescription,
  maxValueDescription,
}: RatingBarChartArgs) => {
  const { i18n } = useTranslation();
  let highestVal = 0;
  for (const bucket of interfaceStats?.buckets || []) {
    if (bucket.count > highestVal) {
      highestVal = bucket.count;
    }
  }
  let data = interfaceStats?.buckets.map((bucket) => {
    const percentage =
      Math.round((bucket.count / interfaceStats.count) * 100) || 0;
    const relativePercentage =
      Math.round((bucket.count / highestVal) * 100) || 0;
    const { rating } = bucket as RatingBucket;
    const remaining = 100 - relativePercentage;
    return { percentage, relativePercentage, rating, remaining };
  });
  if (!data || data.length === 0) {
    return <></>;
  }
  if (i18n.dir() === "rtl") {
    data = data.reverse();
  }

  return (
    <>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={data}
          barCategoryGap={8}
          margin={{
            top: 30,
            right: 0,
            left: 0,
            bottom: 15,
          }}
        >
          <XAxis
            tickLine={false}
            tickMargin={10}
            axisLine={false}
            tick={{ stroke: "white" }}
            dataKey="rating"
          />
          <YAxis hide domain={[0, 100]} />
          <Bar
            dataKey="relativePercentage"
            stackId={"a"}
            fill="#64ffda"
            radius={[0, 0, 5, 5]}
          />
          <Bar
            dataKey="remaining"
            stackId={"a"}
            fill="#4f5d76"
            radius={[5, 5, 0, 0]}
          >
            <LabelList
              style={{
                fontSize: "22px",
                fontWeight: "600",
              }}
              offset={10}
              fill="#fff"
              formatter={(text: number) => `${text}%`}
              dataKey="percentage"
              position="top"
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      <div className="d-flex justify-content-between fs-xs">
        <span className="ms-1">{minValueDescription}</span>
        <span className="me-1 text-right">{maxValueDescription}</span>
      </div>
    </>
  );
};

export default RatingBarChart;
