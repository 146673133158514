import {
  useLocation,
  Navigate,
  Outlet,
  useSearchParams,
} from "react-router-dom";
import { LOGIN } from "../constants/urls";
import useAuth from "../hooks/useAuth";

const RequireAuth = () => {
  const { auth } = useAuth();
  const username = localStorage.getItem("username");
  const location = useLocation();
  const [currentQueryParameters] = useSearchParams();
  const queryParams = currentQueryParameters;
  let { pathname } = location;
  if (currentQueryParameters) {
    pathname = `${pathname}?${queryParams}`;
  }

  return auth?.access_token && auth?.username && auth?.username === username ? (
    <Outlet />
  ) : (
    <Navigate to={LOGIN} state={{ from: { pathname } }} replace />
  );
};

export default RequireAuth;
