import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { CenterCard } from "../components/CenterCard";
import { useNavigate } from "react-router-dom";
import { HOME_PAGE } from "../constants/urls";

export const NotFound = () => {
  const navigate = useNavigate();
  return (
    <CenterCard>
      <Card.Title className="fw-bold fs-4 mb-4">404 Page not found!</Card.Title>
      <Card.Text className="pb-5 px-md-5">
        Oops! We can't seem to find the page you're looking for.
      </Card.Text>

      <Button variant="primary" onClick={() => navigate(HOME_PAGE)}>
        Go back Home
      </Button>
    </CenterCard>
  );
};
