import {
  LiProps,
  ReactMarkdownProps,
  SpecialComponents,
} from "react-markdown/lib/ast-to-react";
import { NormalComponents } from "react-markdown/lib/complex-types";

const components:
  | Partial<Omit<NormalComponents, keyof SpecialComponents> & SpecialComponents>
  | undefined = {
  li: ({ node, className, children }: LiProps) => (
    <li>
      <bdi>{children}</bdi>
    </li>
  ),
  em: ({ node, children }: ReactMarkdownProps) => (
    <em>
      <bdi>{children}</bdi>
    </em>
  ),
  strong: ({ node, children }: ReactMarkdownProps) => (
    <strong>
      <bdi>{children}</bdi>
    </strong>
  ),
  span: ({ node, children }: ReactMarkdownProps) => (
    <span>
      <bdi>{children}</bdi>
    </span>
  ),
  p: ({ node, children }: ReactMarkdownProps) => (
    <p>
      <bdi>{children}</bdi>
    </p>
  ),
};

export default components;
