import Form from "react-bootstrap/Form";
import { FormController } from "@opencraft/providence/base/forms/types/FormController";
import { FormEvent, ReactNode } from "react";

declare interface BoundFormArgs {
  controller: FormController<any>;
  success: (resp: any) => any;
  failure: (resp: any) => any;
  preSend: () => any;
  children: ReactNode;
  className?: string;
}

export const BoundForm = ({
  children,
  controller,
  preSend,
  success,
  failure,
  className,
}: BoundFormArgs) => {
  const submitter = (event: FormEvent) => {
    event.preventDefault();
    preSend();
    controller.submit().then(success, failure).catch(controller.handleError);
  };
  return (
    <Form className={className} noValidate onSubmit={submitter}>
      {children}
    </Form>
  );
};
