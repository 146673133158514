import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, IModuleStore } from "redux-dynamic-modules";
import {
  defaultContextValues,
  ProvidenceContext,
} from "@opencraft/providence-redux/context";
import { NetCallOptions } from "@opencraft/providence/base/types/NetCallOptions";
import { DeriveListArgs } from "@opencraft/providence/base/types/DeriveListArgs";
import { PaginationResult } from "@opencraft/providence/base/types/PaginationResult";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./sass/custom.scss";
import App from "./App";
import { createAxiosInstance, getAuthHeaders } from "./utils/helpers";
import { AuthProvider } from "./context/AuthProvider";
import { NotificationProvider } from "./context/NotificationProvider";
import { fieldLength, requiredField } from "./utils/validators";
import { Suspense } from "react";
import { Spinner } from "react-bootstrap";
import "./i18n";
import "./index.css";

// To begin using Providence, we need to initialize a dynamic module store:
const store: IModuleStore<{}> = createStore({});
const axiosInstance = createAxiosInstance();

// Next we write some client functions. These are functions that handle requests to, and responses from, our API.
//
// The first of these is 'netCall', a function that performs the HTTP request to the server. For this demo, we will be
// using the service https://reqres.in/, which has a bunch of dummy data endpoints for us to play with.
const netCall = <T, K = T>(options: NetCallOptions<T>): Promise<K> => {
  // We add other Axios configuration arguments you need for our API here. That includes
  // things like Authorization headers. Read the Axios documentation for more information.
  const headers = getAuthHeaders();
  return axiosInstance.request({ ...options, headers });
};

// The default function for Singles works with our backend, so our next function to define is for
// deriving lists.
const deriveList = <T,>({
  response,
  state,
}: DeriveListArgs<T>): PaginationResult<T> => {
  if (state.paginated) {
    return {
      list: response.data.results,
      pageInfo: {
        count: response.data.count,
        size: response.config.params?.size,
      },
    };
  }

  return {
    list: response.data,
    pageInfo: {
      count: response.data.length,
      size: response.data.length,
    },
  };
};

// The Providence redux plugin should give you sane defaults. In most cases, the only thing you need to override
// is netCall.
const buildContext = defaultContextValues();
buildContext.client.netCall = netCall;
buildContext.client.deriveList = deriveList;
buildContext.validators.required = requiredField;
buildContext.validators.length = fieldLength;

// withI18n(() => {});
ReactDOM.render(
  <Suspense
    fallback={
      <div className="d-flex justify-content-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    }
  >
    <Provider store={store}>
      <ProvidenceContext.Provider value={buildContext}>
        <BrowserRouter>
          <AuthProvider>
            <NotificationProvider>
              <Routes>
                <Route path="/*" element={<App />} />
              </Routes>
            </NotificationProvider>
          </AuthProvider>
        </BrowserRouter>
      </ProvidenceContext.Provider>
    </Provider>
  </Suspense>,
  // For this example, we're assuming this code will run in an HTML document with a div with an id of 'root'.
  document.getElementById("root")
);
