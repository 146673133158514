import { useSingle } from "@opencraft/providence-redux/hooks";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  EMAIL_VERIFICATION_PATH,
  RESEND_VERIFICATION_EMAIL,
} from "../constants/api-urls";
import { useEffect, useState } from "react";
import { CenterCard } from "../components/CenterCard";
import { Button } from "../components/Button";
import useAuth from "../hooks/useAuth";
import { Token } from "../types/User";
import useNotification from "../hooks/useNotification";
import { useTranslation } from "react-i18next";
import { HOME_PAGE } from "../constants/urls";

interface EmailVerificationState {
  detail: string;
}

const EmailVerification = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const data = {
    client_id: process.env.REACT_APP_CLIENT_ID!,
    client_secret: process.env.REACT_APP_CLIENT_SECRET!,
    user_id: searchParams.get("user_id"),
    timestamp: searchParams.get("timestamp"),
    signature: searchParams.get("signature"),
  };
  const [isLoading, setIsLoading] = useState(true);
  const { saveSession } = useAuth();
  const verificationController = useSingle<EmailVerificationState>(
    "email-verification",
    {
      endpoint: EMAIL_VERIFICATION_PATH,
    }
  );
  const { setNotification } = useNotification();

  const [loading, setLoading] = useState(false);
  const resendEmailController = useSingle<{ id: number }>(
    "resend-verification-email",
    {
      endpoint: RESEND_VERIFICATION_EMAIL,
    }
  );

  useEffect(() => {
    const verify = async () => {
      const resp = await verificationController.post(data);
      await saveSession(resp.data as Token);
      setNotification({
        message: t("emailVerification.notification.emailVerified"),
        variant: "success",
      });
      navigate(HOME_PAGE, { replace: true });
    };

    verify()
      .catch()
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = () => {
    const user_id = data.user_id;
    if (user_id) {
      setLoading(true);
      resendEmailController
        .post({ id: user_id })
        .then(() => {
          setNotification({
            message: t("emailVerification.notification.resendSuccess"),
            variant: "success",
          });
        })
        .catch()
        .finally(() => setLoading(false));
    }
  };

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">{t("pageStatus.loading")}</span>
        </Spinner>
      </div>
    );
  }

  return (
    <CenterCard>
      <div className="px-md-5">
        <h4 className="fw-bold mb-4">{t("emailVerification.form.title")}</h4>
        <p className="text-info">{t("emailVerification.form.description")}</p>
        <Card.Link
          as={Button}
          className="mb-5 mt-4 bg-turquoise"
          loading={loading}
          onClick={handleClick}
        >
          {t("emailVerification.form.resendBtn")}
        </Card.Link>
      </div>
    </CenterCard>
  );
};

export default EmailVerification;
