import { useTranslation } from "react-i18next";
import { Outlet, useSearchParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Nav from "react-bootstrap/Nav";
import * as urls from "../../constants/urls";
import { CustomLink } from "../../components/CustomNavLink";
import { MultiValue, SingleValue } from "react-select";
import { PopupDateRangePicker } from "../../components/report_widgets/PopupDateRangePicker";
import { format } from "date-fns";
import { useList } from "@opencraft/providence-redux/hooks";
import { SHORT_DEFINITION_LIST_API } from "../../constants/api-urls";
import SingleSelectDropdown from "../../components/SingleSelectDropdown";
import { ShortDefinition } from "../../types/Definition";
import { useEffect } from "react";
import { TagFilter } from "../../components/report_widgets/TagFilter";

const ReportBase = () => {
  const { t } = useTranslation();

  const [currentQueryParameters, setSearchParams] = useSearchParams();
  let currentChecklistDefinition = currentQueryParameters.get(
    "checklistDefinition"
  );

  let currentTags = currentQueryParameters.getAll("tags");
  const currentDates = {
    startDate: currentQueryParameters.get("start_date"),
    endDate: currentQueryParameters.get("end_date"),
  };

  function ReportNavbar() {
    const { t } = useTranslation();
    const urlWithParams = (url: string) => `${url}/?${currentQueryParameters}`;
    return (
      <Nav className="mb-0">
        <CustomLink
          className="me-2 ps-0"
          to={urlWithParams(urls.REPORTS_OVERVIEW)}
        >
          {t("reports.navbar.overview")}
        </CustomLink>
        <CustomLink className="mx-2" to={urlWithParams(urls.REPORTS_TRENDS)}>
          {t("reports.navbar.trends")}
        </CustomLink>
        <CustomLink className="mx-2" to={urlWithParams(urls.REPORTS_COMPARE)}>
          {t("reports.navbar.compare")}
        </CustomLink>
        <div className="ms-auto">
          <TagFilter
            selectedTags={currentTags}
            checklistDefinition={currentChecklistDefinition}
            onChange={onTagsChange}
          />
        </div>
      </Nav>
    );
  }

  interface CustomQueryParams {
    name: string;
    values: Array<string | undefined>;
  }

  const setQueryParams = (params: Array<CustomQueryParams>) => {
    const newQueryParameters: URLSearchParams = new URLSearchParams(
      currentQueryParameters
    );
    params.forEach(({ name, values }: CustomQueryParams) => {
      newQueryParameters.delete(name);
      values.forEach((value) => {
        if (value) {
          newQueryParameters.append(name, value);
        }
      });
    });
    setSearchParams(newQueryParameters);
  };

  const onTagsChange = (
    newValues: MultiValue<{ value?: string; label?: string }>
  ) => {
    const tags = newValues.map((tag) => tag.value);
    setQueryParams([{ name: "tags", values: tags }]);
  };

  const onDateRangeChange = (startDate: Date, endDate: Date) => {
    setQueryParams([
      { name: "start_date", values: [format(startDate, "yyyy-MM-dd")] },
      { name: "end_date", values: [format(endDate, "yyyy-MM-dd")] },
    ]);
  };

  const TemplateDropdown = () => {
    const controller = useList<ShortDefinition>("short_definitions_list", {
      endpoint: SHORT_DEFINITION_LIST_API,
      paginated: false,
    });
    controller.getOnce();
    useEffect(() => {
      if (
        !currentChecklistDefinition &&
        controller.list.length === 1 &&
        controller.ready
      ) {
        currentChecklistDefinition = controller.list[0].x!.id;
        setQueryParams([
          { name: "checklistDefinition", values: [currentChecklistDefinition] },
        ]);
      }
    }, [controller]);

    const onDefinitionChange = (
      newValue: SingleValue<{ value?: string; label?: string }>
    ) => {
      setQueryParams([
        { name: "checklistDefinition", values: [newValue?.value] },
      ]);
    };

    return (
      <SingleSelectDropdown
        name={t("chooseTemplate")}
        onChange={onDefinitionChange}
        controller={controller}
        selectedValue={currentChecklistDefinition}
      />
    );
  };

  return (
    <Container>
      <Row className="mb-4">
        <Col>
          <h1 className="fw-bold">{t("reports.title")}</h1>
        </Col>
      </Row>
      <Row>
        <Col md={5}>
          <Form.Label className="fs-xs text-info" htmlFor="templateSelect">
            {t("reports.form.templateLabel")}
          </Form.Label>
          <Form.Control id="templateSelect" as={TemplateDropdown} />
        </Col>
        <Col
          xs={12}
          md={6}
          className="ms-auto d-flex align-items-end justify-content-end"
        >
          <Form.Control
            id="dateRangeSelect"
            as={PopupDateRangePicker}
            currentSelected={currentDates}
            onDateRangeChange={onDateRangeChange}
          />
        </Col>
      </Row>
      <Row className="mt-5">
        <Col className="ms-0">
          <ReportNavbar />
        </Col>
      </Row>
      <hr className="mb-4 mt-1 content-divider" />
      {currentChecklistDefinition ? (
        <Outlet />
      ) : (
        <p className="text-center mt-5 pt-4 text-dark-slate">
          {t("reports.emptySelectionMsg")}
        </p>
      )}
    </Container>
  );
};

export default ReportBase;
