import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Routes, Route, Navigate } from "react-router-dom";

import { Checklists } from "./pages/Checklists";
import { TemplateList } from "./pages/TemplateList";
import { Navbar } from "./components/Navbar";
import { ChecklistDetail } from "./pages/ChecklistDetail";
import { ChecklistProgress } from "./components/ChecklistProgress";
import RequireAuth from "./components/RequireAuth";
import Login from "./pages/Login";
import PersistLogin from "./components/PersistLogin";
import Register from "./pages/Register";
import { EmailSentPage } from "./pages/EmailSentPage";
import ForgotPassword from "./pages/ForgotPassword";
import EmailVerification from "./pages/EmailVerification";
import ResetPassword from "./pages/ResetPassword";
import { ToastNotification } from "./components/ToastNotification";
import { NotFound } from "./pages/NotFound";
import { Confetti } from "./components/Confetti";
import * as urls from "./constants/urls";
import ReportBase from "./pages/reports/ReportBase";
import ReportOverview from "./pages/reports/ReportOverview";
import ReportTrendsPage from "./pages/reports/ReportTrendsPage";
import ReportComparePage from "./pages/reports/ReportComparePage";
import { Footer } from "./components/Footer";
import { useEffect } from "react";
import { matamoTracking } from "./utils/matamo";
import MyChecklistPage from "./pages/MyChecklistPage";

function App() {
  useEffect(() => {
    matamoTracking();
  }, []);
  return (
    <>
      <Confetti />
      <ToastNotification />
      <Navbar />
      <ChecklistProgress />
      <Container className="py-5 mb-5">
        <Row className="mb-5 pb-5">
          <Routes>
            <Route path={urls.LOGIN} element={<Login />} />
            <Route path={urls.REGISTER} element={<Register />} />
            <Route path={urls.FORGOT_PASSWORD} element={<ForgotPassword />} />
            <Route path={urls.RESET_PASSWORD} element={<ResetPassword />} />
            <Route
              path={urls.EMAIL_VERIFICATION_SENT}
              element={<EmailSentPage />}
            />
            <Route path={urls.VERIFY_USER} element={<EmailVerification />} />
            <Route element={<PersistLogin />}>
              <Route element={<RequireAuth />}>
                <Route
                  path="/"
                  element={<Navigate to={urls.HOME_PAGE} replace />}
                />
              </Route>
              <Route element={<RequireAuth />}>
                <Route
                  path={urls.USER_CHECKLISTS}
                  element={
                    <Checklists isArchived={false} key={"currentChecklists"} />
                  }
                />
              </Route>
              <Route element={<RequireAuth />}>
                <Route
                  path={urls.USER_ARCHIVED_CHECKLISTS}
                  element={
                    <Checklists isArchived={true} key={"archivedChecklists"} />
                  }
                />
              </Route>
              <Route element={<RequireAuth />}>
                <Route path={urls.TEMPLATES} element={<TemplateList />} />
              </Route>
              <Route element={<RequireAuth />}>
                <Route path={urls.REPORTS} element={<ReportBase />}>
                  <Route
                    path={urls.REPORTS_OVERVIEW}
                    element={<ReportOverview />}
                  />
                  <Route
                    path={urls.REPORTS_TRENDS}
                    element={<ReportTrendsPage />}
                  />
                  <Route
                    path={urls.REPORTS_COMPARE}
                    element={<ReportComparePage />}
                  />
                </Route>
              </Route>
              <Route element={<RequireAuth />}>
                <Route
                  path={`${urls.USER_CHECKLISTS}/:checklistId`}
                  element={<ChecklistDetail />}
                />
              </Route>
              <Route element={<RequireAuth />}>
                <Route
                  path={`/:runId/mychecklist`}
                  element={<MyChecklistPage />}
                />
              </Route>
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Row>
      </Container>
      {(urls.TOS || urls.PRIVACY_POLICY) && <Footer />}
    </>
  );
}

export default App;
