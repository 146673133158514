import { ReactNode } from "react";
import Card from "react-bootstrap/Card";

declare interface QuestionCardArgs {
  question: string;
  subtitle: string;
  children: ReactNode;
}

export const QuestionCard = ({
  question,
  subtitle,
  children,
}: QuestionCardArgs) => {
  return (
    <Card className="bg-secondary p-4 border-0 rounded-3">
      <Card.Body>
        <div className="mb-3">
          <p className="pb-0 mb-0 text-white">{question}</p>
          <small className="text-info">{subtitle}</small>
        </div>
        {children}
      </Card.Body>
    </Card>
  );
};

export default QuestionCard;
