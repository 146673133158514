import { useEffect, useState } from "react";
import { SearchBar } from "./SearchBar";
import { ReactComponent as MagnifyingGlassIcon } from "../assets/icons/magnifying-glass.svg";

import { Nav } from "react-bootstrap";

declare interface ToggleSearchBarArgs {
  value?: string;
  className?: string;
  searchBarClassName?: string;
  onChange: (newValue: string) => void;
}

export const ToggleSearchBar = ({
  value,
  className,
  searchBarClassName,
  onChange,
}: ToggleSearchBarArgs) => {
  const [isShow, setIsShow] = useState((value || "").length > 0);
  const handleValueChange = (newValue: string) => {
    onChange(newValue);
    if (newValue === "") {
      setIsShow(false);
    }
  };

  useEffect(() => {
    handleValueChange(value || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div className={className}>
      {!isShow ? (
        <Nav.Link
          className="text-end py-0 px-0"
          onClick={() => setIsShow(true)}
        >
          <MagnifyingGlassIcon className="base-icon"></MagnifyingGlassIcon>
        </Nav.Link>
      ) : (
        <SearchBar
          id="search"
          className={searchBarClassName}
          onChange={handleValueChange}
          defaultValue={value}
        />
      )}
    </div>
  );
};
