import { ValidatorArgs } from "@opencraft/providence/base/forms/types/ValidatorArgs";

export const requiredField = async ({
  value,
}: ValidatorArgs<any, any, any>) => {
  if (!!value) {
    return [];
  }
  return ["This field is required."];
};

declare type LengthArgs = {
  min?: number;
  max?: number;
};

export const fieldLength = async ({
  value,
  args,
  fieldName,
}: ValidatorArgs<string, LengthArgs, any>) => {
  if (args.min !== undefined) {
    if (value.length < args.min) {
      return [
        `The ${fieldName.toString()} must contain at least ${
          args.min
        } characters`,
      ];
    }
  }
  if (args.max !== undefined) {
    if (value.length > args.max) {
      return [
        `The ${fieldName.toString()} must not exceed ${args.min} characters`,
      ];
    }
  }
  return [];
};
