import Nav from "react-bootstrap/Nav";
import {
  NavLink,
  LinkProps,
  useMatch,
  useResolvedPath,
} from "react-router-dom";

export const CustomLink = ({ children, to, ...props }: LinkProps) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });
  const isActive = match ? true : false;

  return (
    <div>
      <Nav.Link to={to} as={NavLink} active={isActive} {...props}>
        {children}
      </Nav.Link>
    </div>
  );
};
