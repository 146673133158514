import { useList } from "@opencraft/providence-redux/hooks";
import { TEAMS_API } from "../constants/api-urls";
import { Team, User } from "../types/User";
import { useEffect, useState } from "react";
import Select, { MultiValue, ActionMeta, Options } from "react-select";
import { useTranslation } from "react-i18next";
import { SelectToggleDropdownIndicator } from "./react-select/SelectToggleDropdownIndicator";
import { ValueCountFunc } from "./react-select/types";
import { SelectCheckboxDropdownItemOption } from "./react-select/SelectCheckboxDropdownItemOption";
import { SelectDropdownContainer } from "./react-select/SelectDropdownContainer";
import { dropdownSelectStyles } from "./react-select/styles";
import { SelectDropdownMenu } from "./react-select/SelectDropdownMenu";
import { SelectDropdownMenuList } from "./react-select/SelectDropdownMenuList";

export enum MemberOptionType {
  TEAM = "team",
  MEMBER = "member",
}

interface TeamOption {
  type: MemberOptionType.TEAM;
  label: string;
  value: string;
  members: User[];
}

interface MemberOption {
  type: MemberOptionType.MEMBER;
  label: string;
  value: string;
}

interface GroupedOption {
  readonly label: string;
  readonly options: readonly MemberCustomOption[];
}

export type MemberCustomOption = TeamOption | MemberOption;

const groupMemberOptions = (
  teams: Team[],
  teamLabel: string = "Team",
  memberLabel: string = "Member"
): GroupedOption[] => {
  const usernames: Set<string> = new Set();
  teams
    .map((t) => t.members)
    .flat()
    .forEach((member) => usernames.add(member.username));
  const teamOptions = teams.map((t) => ({
    label: t.name,
    value: t.id,
    type: MemberOptionType.TEAM,
    members: t.members,
  }));
  const memberOptions = Array.from(usernames).map(
    (username) =>
      ({
        label: username,
        value: username,
        type: MemberOptionType.MEMBER,
      } as MemberOption)
  );
  return [
    {
      label: teamLabel,
      options: teamOptions,
    },
    {
      label: memberLabel,
      options: memberOptions,
    },
  ];
};

declare interface MemberFilterArgs {
  className?: string;
  onChange: (
    newValue: MultiValue<MemberCustomOption>,
    actionMeta: ActionMeta<MemberCustomOption>
  ) => void;
  selectedMemberUsernames: Array<string>;
  isLoading?: boolean;
}

const selectedMemberCountFunc: ValueCountFunc = (
  value: Options<MemberCustomOption>
): number => {
  if (!value) {
    return 0;
  }

  if (!!value && !("length" in value)) {
    return 1;
  }

  const values = value as MultiValue<MemberCustomOption>;
  return values.filter((o) => o.type === MemberOptionType.MEMBER).length;
};

export const MemberFilter = ({
  className,
  onChange,
  selectedMemberUsernames,
  isLoading,
}: MemberFilterArgs) => {
  const { t } = useTranslation();
  const teamController = useList<Team>("team", {
    endpoint: TEAMS_API,
    paginated: false,
  });
  teamController.getOnce();
  const [members, setMembers] = useState<Set<User>>(new Set());
  const groupedOptions = groupMemberOptions(
    teamController.list.map((c) => c.x!),
    t("common.team"),
    t("common.member")
  );
  useEffect(() => {
    const membersSet: Set<User> = new Set<User>();
    if (teamController.list.length) {
      teamController.list.forEach((singleController) => {
        singleController.x!.members.forEach((m) => membersSet.add(m));
      });
    }
    setMembers(membersSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamController.list.length]);
  const selected: MemberCustomOption[] = Array.from(members)
    .filter((m) => selectedMemberUsernames.indexOf(m.username) !== -1)
    .map((m) => ({
      label: m.username,
      value: m.username,
      type: MemberOptionType.MEMBER,
    }));
  teamController.list.forEach((singleController) => {
    const members = singleController.x!.members;
    const totalSelected: number = members.filter(
      (m) => selectedMemberUsernames.indexOf(m.username) !== -1
    ).length;
    if (members.length === totalSelected) {
      selected.push({
        label: singleController.x!.name,
        value: singleController.x!.id,
        type: MemberOptionType.TEAM,
        members: members,
      });
    }
  });
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  return (
    <Select
      isMulti
      onChange={onChange}
      options={groupedOptions}
      className={className}
      styles={dropdownSelectStyles}
      isDisabled={isLoading}
      components={{
        IndicatorSeparator: undefined,
        ClearIndicator: undefined,
        Option: SelectCheckboxDropdownItemOption,
        DropdownIndicator: SelectToggleDropdownIndicator({
          label: t("common.team"),
          countFunc: selectedMemberCountFunc,
        }),
        SelectContainer: SelectDropdownContainer({
          className: "filter scroll scrollbar light-scrollbar",
          onDropdownToggle: (nextValue: boolean) => {
            setIsMenuOpen(nextValue);
          },
        }),
        Menu: SelectDropdownMenu,
        MenuList: SelectDropdownMenuList({ hasSearchBar: true }),
      }}
      value={selectedMemberUsernames ? selected : []}
      isLoading={teamController.fetching}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      closeMenuOnScroll={true}
      isSearchable={true}
      tabSelectsValue={false}
      backspaceRemovesValue={false}
      blurInputOnSelect={false}
      controlShouldRenderValue={false}
      menuIsOpen={isMenuOpen}
    />
  );
};
