import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useForm } from "@opencraft/providence-redux/hooks";
import { RESET_PASSWORD_SEND_LINK_PATH } from "../constants/api-urls";
import { useState } from "react";
import { Link } from "react-router-dom";
import { AxiosError } from "axios";
import { CustomAlert } from "../components/Alerts";
import { CenterCard } from "../components/CenterCard";
import { Button } from "../components/Button";
import { BoundForm } from "../components/BoundForm";
import { FieldInput } from "../components/FieldInput";
import { useTranslation, Trans } from "react-i18next";
import { LOGIN } from "../constants/urls";

interface FormValues {
  login: string;
}

const ForgotPassword = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [state, setState] = useState<"sending" | "sent">("sending");

  const formController = useForm<FormValues>("reset-password-form", {
    endpoint: RESET_PASSWORD_SEND_LINK_PATH,
    fields: {
      login: {
        value: "",
        validators: [{ name: "email" }, { name: "required" }],
      },
    },
  });
  const onSuccess = (_resp: FormValues) => {
    setEmail(formController.f.login.model);
    setState("sent");
  };

  const onFailure = (error: AxiosError) => {
    setState("sending");
    const response = error.response?.data as any;
    if (response?.non_field_errors?.length === 0) {
      delete response.non_field_errors;
    }
    formController.handleError(error);
    formController.sending = false;
  };

  return (
    <CenterCard>
      {formController.errors.map((error) => (
        <Col xs={12} key={error}>
          <CustomAlert variant="danger">{error}</CustomAlert>
        </Col>
      ))}
      <div className="px-md-5">
        <Row className="mb-3">
          {state === "sent" && email !== "" ? (
            <>
              <h4 className="mb-2 fw-bold">
                {t("forgotPassword.form.checkEmailTitle")}
              </h4>
              <p>
                <Trans
                  i18nKey="forgotPassword.form.checkEmailDescription"
                  components={[<span className="text-white" />]}
                  values={{ email }}
                />
              </p>
            </>
          ) : (
            <>
              <h4 className="mb-2 fw-bold">
                {t("forgotPassword.form.resetPasswordTitle")}
              </h4>
              <p>{t("forgotPassword.form.resetPasswordDescription")}</p>
            </>
          )}
        </Row>
        <Row>
          <BoundForm
            preSend={() => null}
            controller={formController}
            success={onSuccess}
            failure={onFailure}
            className="text-start"
          >
            {state === "sending" && (
              <FieldInput
                id="email"
                fielder={formController.f.login}
                name="email"
                type="email"
                label={t("forgotPassword.form.email")}
                className="light-input mb-5"
              />
            )}
            <Row className="mt-3">
              <Col className="d-flex justify-content-center">
                <Button
                  className="bg-turquoise"
                  variant="primary"
                  type="submit"
                  loading={formController.sending}
                  disabled={
                    formController.f.login.errors.length > 0 ||
                    formController.sending ||
                    formController.f.login.model.length === 0
                  }
                >
                  {state === "sent" && email !== ""
                    ? t("forgotPassword.form.sendAgainBtn")
                    : t("forgotPassword.form.sendEmailBtn")}
                </Button>
              </Col>
            </Row>
          </BoundForm>
          <small className="mt-5">
            <Trans
              i18nKey="forgotPassword.form.toLoginPage"
              components={[<Link to={LOGIN} />]}
            />
          </small>
        </Row>
      </div>
    </CenterCard>
  );
};

export default ForgotPassword;
