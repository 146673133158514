import { useList, useSingle } from "@opencraft/providence-redux/hooks";
import { AccordionButton, Col, Dropdown, Nav, Row } from "react-bootstrap";
import { ChecklistsTable } from "../components/ChecklistsTable";
import {
  ChecklistArchiveAction,
  ChecklistListName,
  TaskListReview,
} from "../types/Task";
import { useNavigate, useSearchParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import useAuth from "../hooks/useAuth";
import { useTranslation } from "react-i18next";
import { Divider } from "../components/Divider";
import { useCallback, useEffect, useState } from "react";
import {
  MemberCustomOption,
  MemberFilter,
  MemberOptionType,
} from "../components/MemberFilter";
import { StatusCustomOption, StatusFilter } from "../components/StatusFilter";
import { ReactComponent as ListIcon } from "../assets/icons/list.svg";
import { ReactComponent as ArchiveIcon } from "../assets/icons/archive.svg";
import { ReactComponent as CrossIcon } from "../assets/icons/cross.svg";
import {
  ChecklistsTabs,
  parseChecklistListNameFromString,
  TabCountState,
} from "../components/ChecklistsTabs";
import { ToggleSearchBar } from "../components/ToggleSearchBar";
import { CHECKLIST_COUNT_API, CHECKLIST_LIST_API } from "../constants/api-urls";
import { ActionMeta, MultiValue } from "react-select";
import _, { isEqual } from "lodash";
import useNotification from "../hooks/useNotification";

declare interface ChecklistsProps {
  isArchived?: boolean;
}

type GetAction = "filter" | "reset" | "refresh" | "init";

interface GetChecklistArgs {
  action: GetAction;
}

export const defaultCounts = () => ({
  active: {
    TO_DO: 0,
    ASSIGNED_TO_ME: 0,
    ALL: 0,
  },
  archived: {
    TO_DO: 0,
    ASSIGNED_TO_ME: 0,
    ALL: 0,
  },
});

export const Checklists = ({ isArchived }: ChecklistsProps) => {
  const tabCountController = useSingle<{
    active: TabCountState;
    archived: TabCountState;
  }>("tabCount", {
    endpoint: CHECKLIST_COUNT_API,
    x: defaultCounts(),
  });
  const archivedName = isArchived ? "archived" : "active";
  const toDoChecklists = useList<TaskListReview>(
    ["checklists", archivedName, "todo"],
    {
      endpoint: CHECKLIST_LIST_API,
    }
  );
  const assignedToMeChecklists = useList<TaskListReview>(
    ["checklists", archivedName, "assigned"],
    {
      endpoint: CHECKLIST_LIST_API,
    }
  );
  const allChecklists = useList<TaskListReview>(
    ["checklists", archivedName, "all"],
    {
      endpoint: CHECKLIST_LIST_API,
    }
  );
  const tabMap = {
    TO_DO: toDoChecklists,
    ASSIGNED_TO_ME: assignedToMeChecklists,
    ALL: allChecklists,
  };
  // We have to use SingleController here because of unsupported patch method in ListController
  const operateArchiveChecklist = useSingle<Partial<TaskListReview>[]>(
    "operate-archive-checklist",
    {
      endpoint: `${CHECKLIST_LIST_API}`,
    }
  );

  const { t } = useTranslation();
  const { auth } = useAuth();
  const currentUsername = auth?.username!;
  const [searchParams, setSearchParams] = useSearchParams();
  const [isReset, setIsReset] = useState<boolean>(false);
  const [isInitDone, setIsInitDone] = useState<boolean>(false);
  const [selectedStatuses, setSelectedStatuses] = useState<Array<string>>(
    searchParams.getAll("status")
  );
  const [currentIsArchived, setCurrentIsArchived] = useState<boolean>(
    isArchived || false
  );
  const [selectedUsernames, setSelectedUsernames] = useState<Array<string>>(
    searchParams.getAll("username")
  );
  const [searchText, setSearchText] = useState<string>(
    searchParams.get("name") || ""
  );
  const [listName, setListName] = useState<ChecklistListName>(
    parseChecklistListNameFromString(
      searchParams.get("list_name") || "",
      isArchived
    )
  );
  const currentList = tabMap[listName];
  const page = searchParams.get("page") || "1";
  const size = searchParams.get("size") || "24";
  const isFiltering = (): boolean => {
    return (
      selectedUsernames.length > 0 ||
      selectedStatuses.length > 0 ||
      searchText.length > 0
    );
  };
  const reset = () => {
    setSelectedStatuses([]);
    setSelectedUsernames([]);
    setSearchText("");
    setIsReset(true);
  };

  const getChecklists = ({ action }: GetChecklistArgs) => {
    let pageParam = page;
    if (action === "filter" || action === "reset") {
      pageParam = "1";
      const urlSearchParam = new URLSearchParams(searchParams);
      urlSearchParam.set("page", pageParam);
      setSearchParams(urlSearchParam);
    }
    let params = {
      is_archived: currentIsArchived,
      list_name: listName,
      page: pageParam,
      size: size,
      statuses: selectedStatuses.join(","),
      usernames: selectedUsernames.join(","),
      name: searchText,
    };
    if (!isEqual(currentList.params, params) || action === "refresh") {
      currentList.params = params;
      currentList.get();
      tabCountController.get();
    }

    const countParams = _.pick(params, ["statuses", "usernames", "name"]);
    if (
      JSON.stringify(countParams) !==
        JSON.stringify(tabCountController.params) ||
      action === "reset"
    ) {
      tabCountController.params = countParams;
      tabCountController.get();
    }
  };
  const setQueryParams = (
    queryParams: { name: string; values: Array<string | undefined> }[]
  ) => {
    const urlSearchParams = new URLSearchParams(searchParams);
    for (const queryParam of queryParams) {
      urlSearchParams.delete(queryParam.name);
      for (const value of queryParam.values) {
        if (!!value) {
          urlSearchParams.append(queryParam.name, value);
        }
      }
    }
    setSearchParams(urlSearchParams);
  };

  const onChecklistListNameChange = (name: ChecklistListName) => {
    const urlSearchParams = new URLSearchParams(searchParams);
    urlSearchParams.set("list_name", name);
    setSearchParams(urlSearchParams);
    setListName(name);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearchTextChange = useCallback(
    _.debounce((value: string) => setSearchText(value), 500),
    []
  );
  const onSearchTextChange = (newValue: string) => {
    const urlSearchParams = new URLSearchParams(searchParams);
    urlSearchParams.delete("name");
    if (newValue !== "") {
      urlSearchParams.set("name", newValue);
    }
    setSearchParams(urlSearchParams);
    debounceSearchTextChange(newValue);
  };

  const onStatusFilterChange = (
    values: MultiValue<StatusCustomOption>
  ): void => {
    const statuses: Array<string> = [];
    for (const statusOption of values) {
      statuses.push(statusOption.value);
    }
    setQueryParams([{ name: "status", values: statuses }]);
    setSelectedStatuses(statuses);
  };
  const onMemberFilterChange = (
    values: MultiValue<MemberCustomOption>,
    actionMeta: ActionMeta<MemberCustomOption>
  ): void => {
    const usernameSet: Set<string> = new Set();
    for (const memberOption of values) {
      if (memberOption.type === MemberOptionType.MEMBER) {
        usernameSet.add(memberOption.value);
      }
      if (memberOption.type === MemberOptionType.TEAM) {
        memberOption.members!.forEach((member) =>
          usernameSet.add(member.username)
        );
      }
    }
    if (actionMeta.action === "deselect-option") {
      switch (actionMeta.option?.type) {
        case MemberOptionType.TEAM:
          actionMeta.option!.members.forEach((member) =>
            usernameSet.delete(member.username)
          );
          break;
        case MemberOptionType.MEMBER:
          usernameSet.delete(actionMeta.option!.value);
          break;
        default:
          break;
      }
    }
    const usernames = Array.from(usernameSet);
    setQueryParams([{ name: "username", values: Array.from(usernames) }]);
    setSelectedUsernames(usernames);
  };
  const { setNotification } = useNotification();
  const handleFailedArchiveUnarchive = (error: any) => {
    let message = t("checklists.notification.error.undefined");
    if (error.response?.status === 403) {
      message = t("checklists.notification.error.forbidden");
    }
    setNotification({
      message,
      variant: "info",
    });
  };
  const onBulkArchiveUnarchive = (ids: string[], isArchived: boolean) => {
    const action = isArchived
      ? ChecklistArchiveAction.ARCHIVE
      : ChecklistArchiveAction.UNARCHIVE;
    const payload = ids.map((id) => ({ id, is_archived: isArchived }));
    operateArchiveChecklist
      .patch(payload)
      .then((_) => {
        setNotification({
          message: t(`checklists.notification.${action.toLowerCase()}.bulk`, {
            count: ids.length,
          }),
          variant: "success",
        });
        getChecklists({ action: "refresh" });
      })
      .catch(handleFailedArchiveUnarchive);
  };
  const onSingleArchiveUnarchive = (id: string, isArchived: boolean) => {
    const action = isArchived
      ? ChecklistArchiveAction.ARCHIVE
      : ChecklistArchiveAction.UNARCHIVE;
    const controller = currentList.list.filter((c) => c.x!.id === id)[0];
    controller
      .patch({ is_archived: isArchived })
      .then(() => {
        setNotification({
          message: t(`checklists.notification.${action.toLowerCase()}.single`),
          variant: "success",
        });
        getChecklists({ action: "refresh" });
      })
      .catch(handleFailedArchiveUnarchive);
  };
  useEffect(() => {
    if (!isReset) {
      let action: GetAction = "filter";
      // to prevent reset page size in the first time page is loaded
      if (!isInitDone) {
        action = "init";
        setIsInitDone(true);
      }
      getChecklists({ action });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedUsernames,
    selectedStatuses,
    searchText,
    listName,
    currentIsArchived,
  ]);

  useEffect(() => {
    if (isReset) {
      searchParams.delete("username");
      searchParams.delete("list_name");
      searchParams.delete("status");
      searchParams.delete("name");
      getChecklists({ action: "reset" });
      setIsReset(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReset]);

  useEffect(() => {
    if (isArchived === undefined || isArchived === currentIsArchived) {
      return;
    }
    setCurrentIsArchived(isArchived || false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isArchived]);

  const navigate = useNavigate();
  const switchToList = (toIsArchived: boolean = true) => {
    let path = "/lists";
    if (toIsArchived) {
      path = "/archived-lists";
      searchParams.set("list_name", "ALL");
      setListName(ChecklistListName.ALL);
    } else if (isArchived) {
      // If switching to active from archive, change the list to TO_DO.
      searchParams.set("list_name", "TO_DO");
      setListName(ChecklistListName.ALL);
    }
    searchParams.set("page", "1");
    navigate({
      pathname: path,
      search: searchParams.toString(),
    });
  };
  return (
    <Container className="lists-page">
      <Row>
        <Col xs={6}>
          <Dropdown className="d-flex">
            <Dropdown.Toggle
              className="px-0 py-0 text-reset"
              as={Nav.Link}
              to="#"
              split
              id="dropdown-split-basic"
            >
              <AccordionButton className="large">
                <h1 className="fw-bold me-4">
                  {isArchived
                    ? t("userChecklist.archivedListTitle")
                    : t("userChecklist.activeListTitle")}
                </h1>
              </AccordionButton>
            </Dropdown.Toggle>
            <Dropdown.Menu className="p-2 border">
              <Dropdown.Item onClick={() => switchToList(false)}>
                <Row>
                  <Col xs={2}>
                    <ListIcon className="base-icon circle line rect sm" />
                  </Col>
                  <Col xs={10}>
                    <p className="mb-0">{t("userChecklist.activeListTitle")}</p>
                    <small>{`${tabCountController.x!.active.ALL} lists`}</small>
                  </Col>
                </Row>
              </Dropdown.Item>
              <Dropdown.Item onClick={() => switchToList(true)}>
                <Row>
                  <Col xs={2}>
                    <ArchiveIcon className="base-icon sm path" />
                  </Col>
                  <Col xs={10}>
                    <p className="mb-0">
                      {t("userChecklist.archivedListTitle")}
                    </p>
                    <small>{`${
                      tabCountController.x!.archived.ALL
                    } lists`}</small>
                  </Col>
                </Row>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        <Col className="pt-2" xs={6}>
          <div className="d-flex justify-content-end">
            <ToggleSearchBar
              value={searchText}
              onChange={onSearchTextChange}
              className="mx-3"
              searchBarClassName="mt-n3"
            />
            <StatusFilter
              selectedStatuses={selectedStatuses}
              onChange={onStatusFilterChange}
              className="ms-3"
              isLoading={currentList.fetching}
            />
            <MemberFilter
              className="ms-3"
              onChange={onMemberFilterChange}
              selectedMemberUsernames={selectedUsernames}
              isLoading={currentList.fetching}
            />
            {isFiltering() && (
              <Nav.Link className="text-reset p-0 ms-2" onClick={() => reset()}>
                <CrossIcon className="base-icon sm" />
              </Nav.Link>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          {!isArchived && (
            <ChecklistsTabs
              isArchived={isArchived}
              onChange={onChecklistListNameChange}
              tabCountController={tabCountController}
              key={listName}
              defaultListName={listName}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <Divider />
        </Col>
      </Row>
      <Row>
        <Col>
          <ChecklistsTable
            controller={currentList}
            currentUsername={currentUsername}
            isArchived={isArchived}
            onBulkArchiveUnarchive={onBulkArchiveUnarchive}
            onSingleArchiveUnarchive={onSingleArchiveUnarchive}
          />
        </Col>
      </Row>
    </Container>
  );
};
