import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import useAuth from "../hooks/useAuth";
import Spinner from "react-bootstrap/Spinner";
import { useTranslation } from "react-i18next";

const PersistLogin = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { setAuth } = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    const access_token = localStorage.getItem("access_token");
    const username = localStorage.getItem("username");
    if (access_token && username) {
      setAuth({
        username: username,
        access_token: access_token,
      });
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">{t("pageStatuses.loading")}</span>
          </Spinner>
        </div>
      ) : (
        <Outlet />
      )}
    </>
  );
};

export default PersistLogin;
