import { Dropdown } from "react-bootstrap";
import { ContainerProps, GroupBase, components } from "react-select";

interface SelectDropdownContainerArgs {
  className?: string;
  onDropdownToggle: (nextValue: boolean) => void;
}

export const SelectDropdownContainer =
  ({ className, onDropdownToggle }: SelectDropdownContainerArgs) =>
  <Option, Group extends GroupBase<Option>>(
    props: ContainerProps<Option, true, Group>
  ) => {
    return (
      <components.SelectContainer {...props}>
        <Dropdown
          onToggle={onDropdownToggle}
          show={props.selectProps.menuIsOpen}
          className={className}
        >
          {props.children}
        </Dropdown>
      </components.SelectContainer>
    );
  };
