declare interface CircleTextArgs {
  text: string;
  className?: string;
}

export const CircleText = ({ text, className }: CircleTextArgs) => {
  return (
    <div className={`d-block ${className}`}>
      <div className="circle-text text-center d-inline-block align-middle">
        <small className="fs-xs">{text}</small>
      </div>
    </div>
  );
};
