import { ReactNode } from "react";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

declare interface CenterCardArgs {
  children: ReactNode;
}

export const CenterCard = ({ children }: CenterCardArgs) => {
  return (
    <Container>
      <Row className="row text-center justify-content-md-center">
        <Col md={10} lg={8}>
          <Card className="bg-secondary p-5 pb-4 border-0 rounded-3">
            <Card.Body>{children}</Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
