import { useSingle } from "@opencraft/providence-redux/hooks";
import { createContext, ReactElement, useState } from "react";
import { googleLogout } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import {
  GOOGLE_CONVERT_TOKEN_PATH,
  REVOKE_TOKEN_PATH,
  USER_PROFILE,
} from "../constants/api-urls";
import { LOGIN as LOGIN_URL } from "../constants/urls";
import { AuthData, Token, User, LoginMetaData } from "../types/User";
import { clearUserSession, setUserSession } from "../utils/helpers";

interface IAuthContext {
  auth: AuthData | null;
  setAuth: (authInfo: AuthData) => void;
  googleLogin: (access_token: string, redirect_uri: string) => Promise<void>;
  saveSession: (resp: Token, rememberMe?: boolean) => Promise<void>;
  logoutUser: () => Promise<void>;
}

const AuthContext = createContext<IAuthContext>({
  auth: null,
  setAuth: () => {},
  googleLogin: () => new Promise(() => {}),
  saveSession: () => new Promise(() => {}),
  logoutUser: () => new Promise(() => {}),
});

declare interface AuthProviderArgs {
  children: ReactElement;
}

export const AuthProvider = ({ children }: AuthProviderArgs) => {
  const [auth, setAuth] = useState<AuthData>({});
  const navigate = useNavigate();
  // controllers
  const logoutController = useSingle<Token>("logout", {
    endpoint: REVOKE_TOKEN_PATH,
  });
  const convertTokenController = useSingle<Token>("convert-token", {
    endpoint: GOOGLE_CONVERT_TOKEN_PATH,
  });
  const userController = useSingle<User>("user", {
    endpoint: USER_PROFILE,
  });

  const saveSession = async (resp: Token, rememberMe?: boolean) => {
    clearUserSession();
    let refresh_token = undefined;
    if (rememberMe) {
      refresh_token = resp.refresh_token;
    }
    setUserSession({
      access_token: resp.access_token,
      refresh_token,
    });
    await userController.get();
    setAuth({
      id: userController.x?.id,
      email: userController.x?.email,
      username: userController.x?.username,
      access_token: resp.access_token,
      refresh_token,
    });
    setUserSession({
      id: userController.x?.id,
      username: userController.x!.username,
    });
  };

  const googleLogin = async (access_token: string, redirect_uri: string) => {
    const loginData: LoginMetaData = {
      client_id: process.env.REACT_APP_CLIENT_ID!,
      client_secret: process.env.REACT_APP_CLIENT_SECRET!,
      token: access_token,
      backend: "google-oauth2",
      grant_type: "convert_token",
      redirect_uri: redirect_uri,
    };
    const resp = await convertTokenController?.post(loginData);
    await saveSession(resp.data as Token, true);
  };

  const revokeToken = async () => {
    const access_token = localStorage.getItem("access_token")!;
    const logoutData: LoginMetaData = {
      client_id: process.env.REACT_APP_CLIENT_ID!,
      client_secret: process.env.REACT_APP_CLIENT_SECRET!,
      token: access_token,
    };
    await logoutController.post(logoutData);
  };

  const _logoutUser = () => {
    clearUserSession();
    setAuth({});
    navigate(LOGIN_URL, { replace: true });
  };

  const logoutUser = async () => {
    await revokeToken();
    googleLogout();
    _logoutUser();
  };

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        googleLogin,
        logoutUser,
        saveSession,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
