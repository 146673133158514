import useAuth from "../hooks/useAuth";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { LocationState } from "../types/Common";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { ReactComponent as GoogleSvg } from "../assets/icons/google.svg";
import { Button } from "../components/Button";
import { useEffect, useState } from "react";
import { Divider } from "./Divider";
import { useTranslation } from "react-i18next";
import { HOME_PAGE } from "../constants/urls";
import { getCurrentUri, getSavedToken } from "../utils/helpers";

declare interface CustomGoogleLoginArgs {
  setErrors: (errors: string[]) => void;
}

export const CustomGoogleLogin = ({ setErrors }: CustomGoogleLoginArgs) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { googleLogin, logoutUser } = useAuth();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const code = searchParams.get("code");
  const googleState = getSavedToken("googleState");
  const state = searchParams.get("state");
  const location = useLocation();
  const locationState = location.state as LocationState;

  useEffect(() => {
    let didCancel = false;
    const responseGoogle = async (code: string) => {
      setIsLoading(true);
      try {
        await googleLogin(code, getCurrentUri());
        navigate(locationState?.from?.pathname || HOME_PAGE, { replace: true });
      } catch (error) {
        await logoutUser();
        if (axios.isAxiosError(error)) {
          setErrors([error.response?.data?.detail]);
        } else {
          throw error;
        }
      } finally {
        // In order to avoid memory leaks `didCancel` variable is set to `true` on component unmount.
        if (didCancel) return;
        setIsLoading(false);
      }
    };

    if (code != null && state === googleState) {
      responseGoogle(code);
    }
    return () => {
      didCancel = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, state]);

  const login = useGoogleLogin({
    ux_mode: "redirect",
    flow: "auth-code",
    redirect_uri: getCurrentUri(),
    state: googleState,
  });

  return (
    <>
      <Row className="d-flex justify-content-center my-5">
        <Col>
          <Button
            variant="outline"
            className="w-100 border-1 border-info rounded-3"
            onClick={() => login()}
            loading={isLoading}
          >
            <GoogleSvg className="me-2 mb-1" style={{ height: "20px" }} />
            {t("loginWithGoogle")}
          </Button>
        </Col>
      </Row>
      <Divider text="OR" />
    </>
  );
};
