import Alert, { AlertProps } from "react-bootstrap/Alert";
import { ReactComponent as DangerIcon } from "../assets/icons/danger.svg";
import { ReactComponent as WarningIcon } from "../assets/icons/warning-outline.svg";
import { ReactComponent as InfoIcon } from "../assets/icons/question.svg";
import { ReactComponent as SmileyIcon } from "../assets/icons/smiley.svg";
import { ReactElement } from "react";

declare interface AlertArgs extends AlertProps {
  alertTitle?: string;
  alertBody?: string;
  showButtons?: boolean;
  leftButton?: ReactElement;
  rightButton?: ReactElement;
}

export const CustomAlert = ({
  variant,
  className,
  alertTitle,
  alertBody,
  showButtons = false,
  leftButton,
  rightButton,
  children,
  ...props
}: AlertArgs) => {
  let Icon;
  let alignIcon = false;
  let variantClass;

  switch (variant) {
    case "danger":
      Icon = <DangerIcon className="smiley-icon me-2" />;
      variantClass = "bg-danger text-dark border-danger";
      break;
    case "success":
      Icon = <SmileyIcon className="smiley-icon me-2" />;
      variantClass = "bg-success text-dark border-success";
      break;
    case "warning":
      Icon = <WarningIcon className="smiley-icon me-1" />;
      variantClass = "bg-warning text-dark border-warning";
      break;
    case "info":
      Icon = <InfoIcon className="smiley-icon me-2" />;
      variantClass = "bg-info text-dark border-info";
      break;
    default:
      Icon = null;
      variantClass = "";
      break;
  }

  if (alertTitle && showButtons) {
    alignIcon = true;
  }

  return (
    <Alert
      variant={variant}
      className={`${variantClass} ${className}`}
      {...props}
    >
      {alignIcon ? (
        <div className="d-flex justify-content-between">
          <div className="align-self-center">
            {Icon}
            {alertTitle && <span className="fw-bold">{alertTitle}</span>}
            <br />
            {alertBody && <small className="fs-8 ms-3 ps-3">{alertBody}</small>}
          </div>
          {showButtons && (
            <div className="align-self-center me-2">
              {leftButton}
              {rightButton}
            </div>
          )}
        </div>
      ) : (
        <>
          {Icon}
          {children}
        </>
      )}
    </Alert>
  );
};

export default CustomAlert;
