import { ReactNode } from "react";
import { AccordionButton, Dropdown, Nav } from "react-bootstrap";
import { DropdownIndicatorProps, GroupBase, components } from "react-select";
import { CircleText } from "../CircleText";
import { ValueCountFunc } from "./types";

interface SelectToggleDropdownIndicatorArgs {
  label?: string;
  icon?: ReactNode;
  countFunc: ValueCountFunc;
}

export const SelectToggleDropdownIndicator =
  ({ label, icon, countFunc }: SelectToggleDropdownIndicatorArgs) =>
  <Option, Group extends GroupBase<Option> = GroupBase<Option>>(
    props: DropdownIndicatorProps<Option, true, Group>
  ) => {
    const count = countFunc(props.getValue());
    return (
      <components.DropdownIndicator {...props}>
        <Dropdown.Toggle
          className="px-0 d-flex py-0 ms-auto justify-content-end"
          as={Nav.Link}
          split
          id="dropdown-split-basic"
          data-testid="tag-filter-button"
        >
          {count > 0 ? (
            <CircleText className="me-1" text={count.toString()} />
          ) : (
            <></>
          )}
          {icon ? (
            <div className="me-1">{icon}</div>
          ) : (
            <AccordionButton>
              {label && (
                <div className="me-1">
                  <small>{label}</small>
                </div>
              )}
            </AccordionButton>
          )}
        </Dropdown.Toggle>
      </components.DropdownIndicator>
    );
  };
