import { ListController } from "@opencraft/providence/base/lists/types/ListController";
import { useTranslation } from "react-i18next";
import Select, { SingleValue } from "react-select";

interface SingleSelectDropdownArgs {
  name: string;
  onChange: (newValue: SingleValue<{ value?: string; label?: string }>) => void;
  selectedValue?: string | null;
  controller: ListController<any>;
}

export const SingleSelectDropdown = ({
  name,
  onChange,
  selectedValue,
  controller,
}: SingleSelectDropdownArgs) => {
  const { t } = useTranslation();
  const defaultRow = controller.list.find(
    (item) => item.x?.id === selectedValue
  );
  const defaultValue = {
    value: selectedValue ? selectedValue : undefined,
    label: defaultRow?.x?.name,
  };
  const isSingle = controller.list.length === 1 ? true : false;

  return (
    <Select
      className="react-select-container"
      classNamePrefix="react-select"
      placeholder={t("common.select.single.placeholder")}
      onChange={onChange}
      value={selectedValue ? defaultValue : undefined}
      isLoading={controller.fetching}
      isClearable={!isSingle}
      isSearchable={!isSingle}
      name={name}
      options={controller.list.map((item) => {
        return { value: item.x?.id, label: item.x?.name };
      })}
    />
  );
};

export default SingleSelectDropdown;
