import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import Form from "react-bootstrap/Form";
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg";
import { InterfaceComponentProps } from "../../types/Interaction";

const NONE_OF_THE_ABOVE = "None of the above";

export const MultipleChoice = ({
  customizationArgs,
  response,
  required,
}: InterfaceComponentProps) => {
  const { t } = useTranslation();
  const [lastSelectedState, setLastSelectedState] = useState(
    response.model?.selected_choices || null
  );
  const { uid } = response.controller;

  let {
    minimum_selected: min,
    maximum_selected: max,
    available_choices,
  } = customizationArgs;
  const canShowSelectAll = !max || max === available_choices.length;
  const selectionType = max === 1 ? "radio" : "checkbox";

  const onChange = (newValue: string[] | string) => {
    response.model = {
      selected_choices: _.isArray(newValue) ? newValue : [newValue],
    };
  };

  const onNoneSelected = (selected: number[]) => {
    if (0 in selected) {
      setLastSelectedState(response.model?.selected_choices || []);
      onChange([]);
    } else {
      onChange(lastSelectedState);
    }
  };

  const onSelectAllChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setLastSelectedState(response.model?.selected_choices || []);
      onChange(available_choices);
    } else {
      onChange(lastSelectedState);
    }
  };

  let selectionLabel = t("multiChoice.baseLabel");
  if (min && !max) {
    selectionLabel = t("multiChoice.minimumOnly", { count: min });
  } else if (min === max) {
    selectionLabel = t("multiChoice.precise", { count: min });
  } else if (min && max) {
    selectionLabel = t("multiChoice.minmax", { min, max });
  } else if (min === 0 && max) {
    selectionLabel = t("multiChoice.maxOnly", { count: max });
  }

  return (
    <Form.Group>
      <Form.Label
        className="text-info fs-8"
        id={`${uid}-aria-label`}
        htmlFor={`${uid}-aria-div`}
      >
        {selectionLabel}
      </Form.Label>
      <div id={`${uid}-aria-div`} className="d-flex align-items-start">
        {canShowSelectAll && (
          <Form.Check
            inline
            className="text-info fs-8 me-3 mt-4 flex-shrink-0"
            type="checkbox"
            name={`${uid}-select-all-name`}
            id={`${uid}-select-all`}
            onChange={onSelectAllChange}
            checked={
              response.model?.selected_choices?.length ===
              available_choices.length
            }
            label={t("multiChoice.select_all")}
          />
        )}
        <ToggleButtonGroup
          className="d-flex flex-wrap flex-shrink-1"
          name={`${uid}-button-group`}
          type={selectionType}
          value={response.model?.selected_choices}
          onChange={onChange}
        >
          {available_choices.map((choice: string) => (
            <ToggleButton
              tabIndex={-1}
              key={`${uid}-${choice}-options`}
              className="btn-pill-check mx-2 fs-8 px-3 flex-grow-0 my-2"
              variant="outline-primary"
              id={`${uid}-${choice.replaceAll(" ", "_")}`}
              value={choice}
            >
              <CheckIcon className="base-icon white d-none me-1" />
              {choice}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        {required && min === 0 && (
          <ToggleButtonGroup
            className="flex-shrink-0 align-self-end"
            type="checkbox"
            value={[response.model?.selected_choices.length]}
            onChange={onNoneSelected}
          >
            <ToggleButton
              tabIndex={-1}
              className="btn-pill-check mx-2 fs-8 px-3 flex-grow-0 my-2"
              variant="outline-primary"
              id={`${uid}-none-options`}
              value={0}
            >
              <CheckIcon className="base-icon white d-none me-1" />
              {NONE_OF_THE_ABOVE}
            </ToggleButton>
          </ToggleButtonGroup>
        )}
      </div>
    </Form.Group>
  );
};
