import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import i18nHttpBackend from "i18next-http-backend";

i18n
  .use(LanguageDetector)
  .use(i18nHttpBackend)
  .use(initReactI18next)
  .init({
    debug: process.env.NODE_ENV === "development",
    fallbackLng: "en",
    resources: {
      en: {},
    },
    interpolation: {
      escapeValue: true,
    },
    backend: {
      loadPath: "/static/locales/{{lng}}/{{ns}}.json",
    },
    partialBundledLanguages: true,
    detection: {
      lookupQuerystring: "lng",
    },
  });

i18n.on("languageChanged", (lng) => {
  const direction: string = i18n.dir(lng);
  const doc: HTMLHtmlElement = document.getElementsByTagName("html")[0];
  doc.setAttribute("dir", direction);
  doc.setAttribute("lang", lng);
});

export const getLanguageCode: () => string = (): string => {
  return i18n.language;
};
