import { Dropdown } from "react-bootstrap";
import { GroupBase, MenuProps } from "react-select";

export const SelectDropdownMenu = <Option, Group extends GroupBase<Option>>(
  props: MenuProps<Option, true, Group>
) => {
  return (
    <Dropdown.Menu className="px-2" align={{ lg: "end" }}>
      {props.children}
    </Dropdown.Menu>
  );
};
