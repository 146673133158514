export const dropdownSelectStyles = {
  control: () => ({
    background: "initial",
  }),
  valueContainer: () => ({
    display: "none",
  }),
  dropdownIndicator: () => ({
    padding: 0,
  }),
  group: () => ({
    padding: "0.25rem",
  }),
};
