// URLs
export const API_BASE = "/api";
export const WORKFLOW_API = `${API_BASE}/workflow`;
export const PROFILE_API = `${API_BASE}/profile`;
export const TEAMS_API = `${PROFILE_API}/accounts/teams/`;
export const CHECKLIST_COUNT_API = `${WORKFLOW_API}/checklist/list/count/`;
export const CHECKLIST_LIST_API = `${WORKFLOW_API}/checklist/list/`;
export const CHECKLIST_OPERATE_ARCHIVE_API = `${WORKFLOW_API}/checklist/list/operate_archive/`;
export const USER_PROFILE = `${PROFILE_API}/accounts/profile/`;
export const USER_TEAMS = `${PROFILE_API}/accounts/teams/`;
export const USER_TEAM_NAMES = `${PROFILE_API}/accounts/team-names/`;
export const RESEND_VERIFICATION_EMAIL = `${PROFILE_API}/resend_verification_email/`;
export const REGISTER_API = `${PROFILE_API}/accounts/register/`;
export const EMAIL_VERIFICATION_PATH = `${PROFILE_API}/verify-registration/`;
export const RESET_PASSWORD_VERIFICATION_PATH = `${PROFILE_API}/accounts/reset-password/`;
export const RESET_PASSWORD_SEND_LINK_PATH = `${PROFILE_API}/accounts/send-reset-password-link/`;
export const TOKEN_PATH = `/auth/token`;
export const CONVERT_TOKEN_PATH = `/auth/convert-token`;
export const GOOGLE_CONVERT_TOKEN_PATH = `${PROFILE_API}/google-convert-token/`;
export const REVOKE_TOKEN_PATH = `/auth/revoke-token`;
export const DEFINITION_LIST_API = `${WORKFLOW_API}/checklist/definition/`;
export const ONE_OFFS_LIST_API = `${WORKFLOW_API}/checklist/one-offs/`;
export const SHORT_DEFINITION_LIST_API = `${WORKFLOW_API}/checklist/short-definition/`;
export const MARKDOWNGUIDE_URL = "https://www.markdownguide.org/basic-syntax/";
