import { useEffect } from "react";
import { SingleController } from "@opencraft/providence/base/singles/types/SingleController";
import Accordion from "react-bootstrap/Accordion";
import { Task } from "../types/Task";
import { Body } from "./Body";
import { Title } from "./Title";
import { Interaction } from "./interactions/Interaction";
import { CheckboxHeader } from "./CheckboxHeader";
import { useTranslation } from "react-i18next";
import CustomAlert from "./Alerts";

declare interface TaskItemBase {
  controller: SingleController<Task>;
}

export const TaskItem = ({ controller }: TaskItemBase) => {
  const { t } = useTranslation();

  const body = controller.x?.body || "";
  const showExpansion = body || controller.x?.interface_type !== "checkbox";

  useEffect(() => {
    if (controller.p.completed.model) {
      controller.p.completed.errors = [];
    }
  }, [controller.p.completed, controller.p.completed.model]);
  const extraClass = controller.p.completed.errors.length ? "text-danger" : "";

  return (
    <Accordion.Item
      className="border-top border-light-navy border-2"
      eventKey={controller.x!.id}
      key={controller.x!.id}
    >
      {!!controller.p.completed.errors.length && (
        <div className={"pt-2"}>
          {controller.p.completed.errors.map((error: string) => (
            <CustomAlert
              key={error}
              variant="danger"
              className={"required-missing py-1 mb-0"}
            >
              {error}
            </CustomAlert>
          ))}
        </div>
      )}
      <Accordion.Button
        tabIndex={showExpansion ? undefined : -1}
        aria-label={showExpansion ? t("taskItem.showDetails") : ""}
        className={showExpansion ? "" : "hide-toggle-button"}
      >
        <div className="w-100 d-flex justify-content-between">
          {controller.x!.required && (
            <span className={"checklist-required-marker " + extraClass}>*</span>
          )}
          {controller.x?.interface_type === "checkbox" ? (
            <CheckboxHeader
              response={controller.p.response}
              label={controller.x!.label}
              id={controller.x!.id}
            />
          ) : (
            <div dir="auto">
              <Title text={controller.x!.label} />
            </div>
          )}
        </div>
      </Accordion.Button>
      {showExpansion && (
        <Accordion.Body
          className={`py-2 ${
            controller.x?.interface_type === "checkbox" ? "ms-45" : "mb-3"
          }`}
        >
          {body && <Body text={controller.x!.body} />}
          {controller.x?.interface_type !== "checkbox" && (
            <Interaction
              interfaceType={controller.x!.interface_type}
              customizationArgs={controller.x?.customization_args}
              response={controller.p.response}
              required={controller.x?.required}
            />
          )}
        </Accordion.Body>
      )}
    </Accordion.Item>
  );
};
