import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useForm } from "@opencraft/providence-redux/hooks";
import { RESET_PASSWORD_VERIFICATION_PATH } from "../constants/api-urls";
import { HOME_PAGE, LOGIN as LOGIN_URL } from "../constants/urls";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AxiosError } from "axios";
import { CustomAlert } from "../components/Alerts";
import { CenterCard } from "../components/CenterCard";
import { Password } from "../components/Password";
import { Button } from "../components/Button";
import { BoundForm } from "../components/BoundForm";
import useNotification from "../hooks/useNotification";
import { useTranslation } from "react-i18next";

interface ResetPasswordState {
  user_id: string;
  timestamp: number;
  signature: string;
  password: string;
}

const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const data = Object.fromEntries(searchParams.entries());
  const { setNotification } = useNotification();
  const { t } = useTranslation();

  useEffect(() => {
    if (
      !["user_id", "timestamp", "signature"].every((key) =>
        Object.keys(data).includes(key)
      )
    ) {
      setNotification({
        message: t("resetPassword.invalidResetLink"),
        variant: "danger",
      });
      navigate(HOME_PAGE, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formController = useForm<ResetPasswordState>("reset-password-form", {
    endpoint: RESET_PASSWORD_VERIFICATION_PATH,
    fields: {
      user_id: { value: searchParams.get("user_id")! },
      timestamp: { value: parseInt(searchParams.get("timestamp")!) },
      signature: { value: searchParams.get("signature")! },
      password: {
        value: "",
        validators: [
          { name: "required" },
          { name: "length", args: { min: 8 } },
        ],
      },
    },
  });

  const onSuccess = (_resp: ResetPasswordState) => {
    navigate(LOGIN_URL, { replace: true });
  };

  const onFailure = (error: AxiosError) => {
    const response = error.response?.data as any;
    if (response?.non_field_errors?.length === 0) {
      delete response.non_field_errors;
    }
    formController.handleError(error);
    formController.sending = false;
  };

  useEffect(() => {
    formController.clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formController.f.password.model]);

  return (
    <CenterCard>
      {formController.errors.map((error) => (
        <Col xs={12} key={error}>
          <CustomAlert variant="danger">{error}</CustomAlert>
        </Col>
      ))}
      <div className="px-md-5">
        <Row className="text-center">
          <h4 className="fw-bold mb-2">{t("resetPassword.form.title")}</h4>
          <p>{t("resetPassword.form.description")}</p>
        </Row>
        <Row>
          <BoundForm
            preSend={() => null}
            controller={formController}
            success={onSuccess}
            failure={onFailure}
            className="text-start mb-4"
          >
            <Form.Group className="mb-3">
              <Password showMeter light fielder={formController.f.password} />
            </Form.Group>
            <Row className="mt-5">
              <Col className="d-flex justify-content-center">
                <Button
                  className="bg-turquoise"
                  variant="primary"
                  type="submit"
                  loading={formController.sending}
                >
                  {t("resetPassword.form.saveBtn")}
                </Button>
              </Col>
            </Row>
          </BoundForm>
        </Row>
      </div>
    </CenterCard>
  );
};

export default ResetPassword;
