import { SingleController } from "@opencraft/providence/base/singles/types/SingleController";
import { ReactElement } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useTranslation } from "react-i18next";

import { Task } from "../types/Task";
import { Title } from "./Title";
import { Body } from "./Body";
import Badge from "react-bootstrap/Badge";

export type SubsectionTask = Task<"subsection">;

declare interface SubsectionItem {
  controller: SingleController<SubsectionTask>;
  isCompleted: boolean;
  children: ReactElement[];
}

export const Subsection = ({
  controller,
  isCompleted,
  children,
}: SubsectionItem) => {
  const { t } = useTranslation();
  const body = controller.x!.body || "";
  const showBody = !!(body || children);

  return (
    <Accordion.Item
      className="border-top border-light-navy border-2 checklist-subsection"
      eventKey={controller.x!.id}
      key={controller.x!.id}
    >
      <Accordion.Button
        tabIndex={undefined}
        aria-label={t("taskItem.showDetails")}
        className=""
      >
        <div className="w-100 d-flex justify-content-between">
          <div dir="auto">
            {isCompleted ? (
              <del>
                <Title text={controller.x!.label} />
              </del>
            ) : (
              <Title text={controller.x!.label} />
            )}
            &nbsp;
            <Badge pill bg="secondary">
              {children.length}
            </Badge>
          </div>
        </div>
      </Accordion.Button>
      {showBody && (
        <Accordion.Body>
          {body && <Body text={controller.x!.body} />}
          {children}
        </Accordion.Body>
      )}
    </Accordion.Item>
  );
};
