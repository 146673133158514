import { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { useLocation, useNavigate } from "react-router-dom";
import { CenterCard } from "../components/CenterCard";
import { RESEND_VERIFICATION_EMAIL } from "../constants/api-urls";
import { useSingle } from "@opencraft/providence-redux/hooks";
import { Button } from "../components/Button";
import useNotification from "../hooks/useNotification";
import { Trans, useTranslation } from "react-i18next";
import { HOME_PAGE } from "../constants/urls";

export const EmailSentPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation() as { state?: { id: number; email: string } };
  const [loading, setLoading] = useState(false);
  const resendEmailController = useSingle<{ id: number }>(
    "resend-verification-email",
    {
      endpoint: RESEND_VERIFICATION_EMAIL,
    }
  );
  const { setNotification } = useNotification();

  useEffect(() => {
    const access_token = localStorage.getItem("access_token");
    if (access_token) {
      navigate(HOME_PAGE, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = () => {
    const user_id = state?.id;
    if (user_id) {
      setLoading(true);
      resendEmailController
        .post({ id: user_id })
        .then(() => {
          setNotification({
            message: t("emailSent.notification.resendSuccess"),
            variant: "success",
          });
        })
        .catch()
        .finally(() => setLoading(false));
    }
  };

  return (
    <CenterCard>
      <Card.Title className="fw-bold fs-4 mb-4">
        {t("emailSent.form.title")}
      </Card.Title>
      <Card.Text className="pb-5 px-md-5">
        <Trans
          i18nKey="emailSent.form.description"
          components={[<span className="text-white" />]}
          values={{ email: state?.email }}
        />
      </Card.Text>
      <small>
        <Trans
          i18nKey="emailSent.form.resend"
          components={[
            <Card.Link
              as={Button}
              className="ms-1 p-0"
              variant="link"
              size="sm"
              loading={loading}
              onClick={handleClick}
            />,
          ]}
        />
      </small>
    </CenterCard>
  );
};
