import ReactMarkdown from "react-markdown";
import ReactMarkdownOverrides from "./ReactMarkdownOverrides";

declare interface TitleArgs {
  text: string;
}

const allowedElements = ["em", "strong", "span", "code"];

export const Title = ({ text }: TitleArgs) => {
  return (
    <ReactMarkdown
      children={text}
      allowedElements={allowedElements}
      unwrapDisallowed
      components={ReactMarkdownOverrides}
    />
  );
};
