import { SingleController } from "@opencraft/providence/base/singles/types/SingleController";
import { ListController } from "@opencraft/providence/base/lists/types/ListController";
import { ReactElement } from "react";
import Spinner from "react-bootstrap/Spinner";
import { ErrorAlerts } from "./ErrorAlerts";
import { useTranslation } from "react-i18next";

declare interface LoadSectionArgs {
  controllers: Array<SingleController<any> | ListController<any>>;
  children: () => ReactElement;
}

export const LoadSection = ({ controllers, children }: LoadSectionArgs) => {
  const { t } = useTranslation();
  // This component serves as an example wrapper that can defer rendering until any relevant Providence controllers
  // are finished fetching. It will also display any errors once finished.
  const fetching = !!controllers.filter((controller) => controller.fetching)
    .length;
  const ready =
    controllers.filter((controller) => controller.ready).length ===
    controllers.length;
  if (fetching) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">{t("pageStatuses.loading")}</span>
        </Spinner>
      </div>
    );
  }
  if (ready) {
    const Contents = children();
    return <>{Contents}</>;
  }
  return <ErrorAlerts controllers={controllers} />;
};
