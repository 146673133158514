import { useLayoutEffect, useRef, useState } from "react";
import { Card } from "react-bootstrap";
import { DisplayUser } from "../types/User";
import { MiniAvatar } from "./MiniAvatar";

declare interface AssigneesCardArgs {
  id: string;
  assignees: DisplayUser[];
}

const avatarWidth = 28;

export const AssigneesCard = ({ id, assignees }: AssigneesCardArgs) => {
  const ref = useRef<HTMLHeadingElement>(null);
  const [visibleAssignees, setVisibleAvatars] = useState(
    assignees.slice(0, 10)
  );
  useLayoutEffect(() => {
    const width = ref.current ? ref.current.offsetWidth : 0;
    if (width > 0) {
      setVisibleAvatars(assignees.slice(0, Math.round(width / avatarWidth)));
    }
  }, [assignees]);

  return (
    <div ref={ref} className="assignees scrollbar black-scrollbar d-flex">
      <div className="d-flex">
        {visibleAssignees.map((assignee) => (
          <MiniAvatar
            className={
              visibleAssignees.length < assignees.length ? "fade-last" : ""
            }
            key={`short-${id}-${assignee.username}`}
            user={assignee}
            useBorder
          />
        ))}
      </div>
      <Card className="full">
        <Card.Body>
          {assignees.map((assignee, index) => (
            <div
              key={`full-${id}-${assignee.username}`}
              className={`d-flex mb-0 ${index === 0 ? "" : "pt-2"}`}
            >
              <MiniAvatar className="me-2" user={assignee} />
              <span
                title={assignee.username}
                className="d-inline-block text-dark text-truncate w-90"
              >
                {assignee.display_name}
              </span>
            </div>
          ))}
        </Card.Body>
      </Card>
    </div>
  );
};
