import Form from "react-bootstrap/Form";
import { Fielder } from "@opencraft/providence/base/forms/types/Fielder";
import { ChangeEvent } from "react";

declare interface FieldInputBase<T> {
  fielder: Fielder<T, keyof T>;
  label: string;
  name: string;
  type: string;
  id: string;
  className?: string;
  isInvalid?: boolean;
}

export const FieldInput = <T,>({
  fielder,
  id,
  label,
  name,
  type,
  className,
  isInvalid,
  ...props
}: FieldInputBase<T>) => {
  const disabled = fielder.disabled || fielder.controller.sending;
  return (
    <Form.Group className={className}>
      <Form.Label htmlFor={id}>{label}</Form.Label>
      <Form.Control
        id={id}
        name={name}
        value={fielder.model as any}
        disabled={disabled}
        {...props}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          (fielder.model = event.target.value as any)
        }
        onBlur={fielder.validate}
        aria-describedby={`${id}-feedback`}
        isInvalid={fielder.errors.length > 0 || isInvalid}
        type={type}
        dir="auto"
      />
      <Form.Control.Feedback type="invalid">
        <bdi>{fielder.errors}</bdi>
      </Form.Control.Feedback>
    </Form.Group>
  );
};
