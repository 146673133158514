import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AssigneesCard } from "./AssigneesCard";
import { dateString, isFirstOlderThanSecond } from "../utils/helpers";
import { ChecklistAction } from "./ChecklistAction";
import { SingleController } from "@opencraft/providence/base/singles/types/SingleController";
import { TaskListReview } from "../types/Task";
import { format, parseISO } from "date-fns";
import { REPORTS_COMPARE } from "../constants/urls";
import { useTranslation } from "react-i18next";

declare interface ColSizes {
  xs: number[];
  sm: number[];
  md: number[];
  lg: number[];
}

declare interface ChecklistTableRowArgs {
  singleController: SingleController<TaskListReview>;
  currentUsername: string;
  isArchived: boolean;
  isChecklistSelected: (id: string) => boolean;
  setChecklistSelectState: (id: string, value: boolean) => void;
  onSingleArchiveUnarchive: (id: string, isArchived: boolean) => void;
  colSizes: ColSizes;
}

export const ChecklistTableRow = ({
  singleController,
  currentUsername,
  isArchived,
  setChecklistSelectState,
  isChecklistSelected,
  onSingleArchiveUnarchive,
  colSizes,
}: ChecklistTableRowArgs) => {
  const isCurrentUserAssignee =
    singleController.x!.assignee.username === currentUsername;
  const isClickable =
    (isCurrentUserAssignee || singleController.x!.run) && !isArchived;
  const { t } = useTranslation();

  const getTaskReportLink = (taskList: TaskListReview): string => {
    if (!(taskList.run && taskList.run.recurrence)) {
      return "";
    }
    const reportQueryParams = new URLSearchParams({
      checklistDefinition: taskList.run.recurrence.checklist_definition,
      team_ids: taskList.run.recurrence.team,
      start_date: format(parseISO(taskList.run.start_date), "yyyy-MM-dd"),
      end_date: format(parseISO(taskList.run.end_date), "yyyy-MM-dd"),
    });
    return `${REPORTS_COMPARE}?${reportQueryParams.toString()}`;
  };

  const getDetailLink = (taskList: TaskListReview) => {
    if (!isArchived && currentUsername === taskList.assignee.username) {
      return `/lists/${taskList.id}/`;
    } else {
      const reportLink = getTaskReportLink(taskList);
      if (reportLink) {
        return reportLink;
      }
    }
    return "#";
  };

  const singleUnarchive = () => {
    onSingleArchiveUnarchive(singleController.x!.id, false);
  };

  const detailLink = getDetailLink(singleController.x!);
  const [isHovering, setIsHovering] = useState(false);
  const getCompleteTaskCount = (taskList: TaskListReview): number =>
    taskList.run?.completed_checklist_count || 0;

  const getTotalTaskCount = (taskList: TaskListReview) =>
    taskList.run?.total_checklist_count || 0;

  return (
    <Row
      key={singleController.x!.id}
      className="item mb-2 rounded py-4 me-0 ms-0 fs-sm run-listing-item"
      onMouseLeave={() => setIsHovering(false)}
    >
      {isArchived && (
        <div
          aria-hidden={true}
          className={
            "unarchive-overlay rounded" + (isHovering ? " activated" : "")
          }
          onClick={singleUnarchive}
        >
          <div className={"unarchive-label"}>
            {t("checklists.table.row.clickToUnarchive")}
          </div>
        </div>
      )}
      {isClickable && (
        <Link to={detailLink} className={"link-overlay rounded"}>
          <span className={"d-none"}>{singleController.x!.name}</span>
        </Link>
      )}
      <Col
        xs={colSizes.xs[0]}
        sm={colSizes.sm[0]}
        md={colSizes.md[0]}
        lg={colSizes.lg[0]}
        onMouseEnter={() => setIsHovering(false)}
      >
        <Form.Check.Input
          type="checkbox"
          className="me-3 sm interaction"
          id={`run-${singleController.x!.id}-checkbox`}
          onChange={() => {
            setChecklistSelectState(
              singleController.x!.id,
              !isChecklistSelected(singleController.x!.id)
            );
          }}
          checked={isChecklistSelected(singleController.x!.id)}
        />
        <Form.Check.Label
          title={singleController.x!.name}
          htmlFor={`run-${singleController.x!.id}-checkbox`}
          className="fw-bold d-inline-block text-truncate w-50 w-xl-75 white interaction"
        >
          <bdi>{singleController.x!.name}</bdi>
        </Form.Check.Label>
      </Col>
      <Col
        className="d-none d-md-block"
        xs={colSizes.xs[1]}
        sm={colSizes.sm[1]}
        md={colSizes.md[1]}
        lg={colSizes.lg[1]}
        onMouseEnter={() => setIsHovering(true)}
      >
        <AssigneesCard
          id={singleController.x!.id}
          assignees={
            singleController.x!.run?.assignees || [singleController.x!.assignee]
          }
        />
      </Col>
      <Col
        className="d-none d-lg-block"
        xs={colSizes.xs[2]}
        sm={colSizes.sm[2]}
        md={colSizes.md[2]}
        lg={colSizes.lg[2]}
        onMouseEnter={() => setIsHovering(true)}
      >
        <span className="noselect">
          {!!singleController.x!.run
            ? t("checklists.table.row.progress", {
                completed: getCompleteTaskCount(singleController.x!),
                total: getTotalTaskCount(singleController.x!),
              })
            : t("checklists.table.row.progress", {
                completed: singleController.x!.completed ? 1 : 0,
                total: 1,
              })}
        </span>
      </Col>
      <Col
        className="d-none d-lg-block"
        xs={colSizes.xs[3]}
        sm={colSizes.sm[3]}
        md={colSizes.md[3]}
        lg={colSizes.lg[3]}
        onMouseEnter={() => setIsHovering(true)}
      >
        <div className="d-inline-block text-truncate w-75 noselect">
          {!!(singleController.x!.run && singleController.x!.run.recurrence) ? (
            <span
              title={
                singleController.x!.run.recurrence.recurring_schedule_display
              }
            >
              {singleController.x!.run.recurrence.recurring_schedule_display}
            </span>
          ) : (
            <span>{t("checklists.table.row.one_off")}</span>
          )}
        </div>
      </Col>
      {!isArchived && (
        <Col
          xs={colSizes.xs[4]}
          sm={colSizes.sm[4]}
          md={colSizes.md[4]}
          lg={colSizes.lg[4]}
        >
          {isCurrentUserAssignee && (
            <div
              className={`noselect checklist-status ${singleController.x!.status.toLowerCase()}`}
            >
              <small>
                {t(
                  `checklistStatus.${singleController.x!.status.toLowerCase()}`
                )}
              </small>
            </div>
          )}
        </Col>
      )}
      <Col
        xs={colSizes.xs[5]}
        sm={colSizes.sm[5]}
        md={colSizes.md[5]}
        lg={colSizes.lg[5]}
        onMouseEnter={() => setIsHovering(true)}
      >
        <Row>
          <Col className="d-none d-sm-block pe-0" xs={0} sm={9} md={10} lg={9}>
            <span
              className={`${
                !singleController.x!.completed &&
                isFirstOlderThanSecond(
                  singleController.x!.run?.due_date,
                  new Date()
                )
                  ? "text-warning"
                  : ""
              } noselect`}
            >
              {dateString(singleController.x!.run?.due_date)}
            </span>
          </Col>
          <Col className="p-0 pe-1" xs={12} sm={3} md={2} lg={3}>
            {!isArchived && (
              <ChecklistAction
                onArchiveUnarchive={onSingleArchiveUnarchive}
                controller={singleController}
                isOwnedByCurrentUser={isCurrentUserAssignee}
                taskReportLink={getTaskReportLink(singleController.x!)}
              />
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
