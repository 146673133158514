import ReactMarkdown from "react-markdown";
import ReactMarkdownOverrides from "./ReactMarkdownOverrides";
import remarkGfm from "remark-gfm";

declare interface BodyArgs {
  text: string;
}

export const Body = ({ text }: BodyArgs) => {
  return (
    <ReactMarkdown
      children={text}
      remarkPlugins={[remarkGfm]}
      components={ReactMarkdownOverrides}
    />
  );
};
