import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import { ReactComponent as ShowIcon } from "../assets/icons/eye.svg";
import { ReactComponent as HideIcon } from "../assets/icons/eye-slash.svg";
import { ChangeEvent, useEffect, useState } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { zxcvbn, zxcvbnOptions, ZxcvbnResult } from "@zxcvbn-ts/core";
import { Fielder } from "@opencraft/providence/base/forms/types/Fielder";
import { useTranslation } from "react-i18next";

declare interface PasswordArgs<T> {
  fielder: Fielder<T, keyof T>;
  light?: boolean;
  label?: string;
  showMeter?: boolean;
  isInvalid?: boolean;
}

export const Password = <T,>({
  fielder,
  light,
  label,
  showMeter,
  isInvalid,
}: PasswordArgs<T>) => {
  const { t } = useTranslation();
  const [passwordType, setPasswordType] = useState<"password" | "text">(
    "password"
  );
  const value = fielder.model as unknown as string;
  const disabled = fielder.disabled || fielder.controller.sending;

  const [showHelp, setShowHelp] = useState(false);
  let color = "";
  let strengthText = "";
  let strength: ZxcvbnResult;
  let scorePercentage = 0;
  useEffect(() => {
    const loadOptions = async () => {
      const zxcvbnCommonPackage = await import(
        /* webpackChunkName: "zxcvbnCommonPackage" */ "@zxcvbn-ts/language-common"
      );
      const zxcvbnEnPackage = await import(
        /* webpackChunkName: "zxcvbnEnPackage" */ "@zxcvbn-ts/language-en"
      );

      zxcvbnOptions.setOptions({
        dictionary: {
          ...zxcvbnCommonPackage.default.dictionary,
          ...zxcvbnEnPackage.default.dictionary,
        },
        graphs: zxcvbnCommonPackage.default.adjacencyGraphs,
        translations: zxcvbnEnPackage.default.translations,
      });
    };

    loadOptions().catch();
  }, []);

  if (showMeter) {
    strength = zxcvbn(value);
    // maximum score is 4
    scorePercentage = (strength.score * 100) / 4;
    switch (strength.score) {
      case 1:
        color = "danger";
        strengthText = t("password.meter.weak");
        break;
      case 2:
        color = "primary";
        strengthText = t("password.meter.okay");
        break;
      case 3:
        color = "primary";
        strengthText = t("password.meter.good");
        break;
      case 4:
        color = "success";
        strengthText = t("password.meter.strong");
        break;
      default:
        color = "danger";
        strengthText = t("password.meter.tooShort");
        break;
    }
  }

  return (
    <>
      <Row className="mb-0">
        <Col xs={6}>
          <Form.Label htmlFor="password">
            {label ? label : t("password.defaultLabel")}
          </Form.Label>
        </Col>
        <Col xs={6} className="d-flex justify-content-end">
          <Button
            className="border-0 text-info"
            variant="link"
            onClick={() =>
              setPasswordType(passwordType === "password" ? "text" : "password")
            }
            size="sm"
          >
            {passwordType === "password" ? (
              <>
                <ShowIcon className="me-1 slate-icon" />
                <span className="text-info">{t("password.showBtn")}</span>
              </>
            ) : (
              <>
                <HideIcon className="me-1 slate-icon" />
                <span className="text-info">{t("password.hideBtn")}</span>
              </>
            )}
          </Button>
        </Col>
      </Row>
      <InputGroup hasValidation className={light ? "light-input" : ""}>
        <Form.Control
          type={passwordType}
          name="password"
          value={value}
          id="password"
          disabled={disabled}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            (fielder.model = event.target.value as any)
          }
          onFocus={() => setShowHelp(true)}
          onBlur={() => {
            setShowHelp(false);
            fielder.validate();
          }}
          isInvalid={fielder.errors.length > 0 || isInvalid}
          className={`${light ? "light-input" : ""} ${
            value.length === 0 || !showMeter ? "border-focus-end" : ""
          }`}
        />
        {showMeter && !fielder.errors?.length && value.length > 0 && (
          <InputGroup.Text
            className={`text-${color} ${
              light ? "light-input" : "bg-secondary"
            }`}
          >
            <bdi>{strengthText}</bdi>
          </InputGroup.Text>
        )}
        <Form.Control.Feedback type="invalid">
          <bdi>{fielder.errors}</bdi>
        </Form.Control.Feedback>
      </InputGroup>
      {showMeter && !fielder.errors?.length && (
        <ProgressBar
          className="mb-0 rounded-3 password-progress-bar"
          variant={color}
          now={scorePercentage}
        />
      )}
      {showMeter && !fielder.errors?.length && showHelp && (
        <Form.Text id="password" muted className="mt-0 pt-0">
          <bdi>{t("password.validation.minLength", { length: 8 })}</bdi>
        </Form.Text>
      )}
    </>
  );
};
