import { useSingle } from "@opencraft/providence-redux/hooks";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { LoadSection } from "../../components/LoadSection";
import AnswerTable from "../../components/report_widgets/AnswerTable";
import { Title } from "../../components/Title";
import { DEFINITION_LIST_API } from "../../constants/api-urls";
import { CompareReport } from "../../types/Report";
import DownloadButton from "../../components/report_widgets/DownloadButton";
import { useTranslation } from "react-i18next";

const ReportComparePage = () => {
  const { t } = useTranslation();
  const [currentQueryParameters] = useSearchParams();

  const controller = useSingle<CompareReport>("compare-report", {});
  const [reportData, setReportData] = useState<CompareReport>({});

  useEffect(() => {
    const currentChecklistDefinition = currentQueryParameters.get(
      "checklistDefinition"
    );
    const baseUrl = `${DEFINITION_LIST_API}${currentChecklistDefinition}/compare/`;
    const queryParams = currentQueryParameters.toString();
    if (queryParams && currentChecklistDefinition) {
      controller.endpoint = `${baseUrl}?${queryParams}`;
      controller.x = {}; // clear cache else on null response old value is reused.
      controller.get().then((resp) => {
        setReportData(resp);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQueryParameters]);

  return (
    <>
      <LoadSection controllers={[controller]}>
        {() => (
          <>
            {Object.keys(reportData).length === 0 ? (
              <p className="text-center mt-5 pt-4 text-dark-slate">
                {t("reports.noResultsMsg")}
              </p>
            ) : (
              <>
                <DownloadButton
                  baseUrl={controller.endpoint}
                  name="compare-report"
                />
                {Object.keys(reportData).map((question) => {
                  const uniqueKey = `${question} - ${reportData[
                    question
                  ].dates.join()}`;
                  return (
                    <div className="pt-5" key={uniqueKey}>
                      <span className="text-white">
                        <Title text={reportData[question].task_label} />
                      </span>
                      <div className="mt-3">
                        <AnswerTable data={reportData[question]} />
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </>
        )}
      </LoadSection>
    </>
  );
};

export default ReportComparePage;
