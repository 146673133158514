import { useState, MouseEvent } from "react";
import Form from "react-bootstrap/Form";

import { Title } from "./Title";

declare interface CheckboxHeaderArgs {
  response: any;
  label: string;
  id: string;
}

export const CheckboxHeader = ({ id, response, label }: CheckboxHeaderArgs) => {
  let [checkboxChecked, setCheckboxChecked] = useState(
    response.model ? response.model.checked : false
  );

  let handleCheckBoxChangeEvent = (event: any) => {
    response.model = {
      checked: event.target.checked,
    };
    setCheckboxChecked(event.target.checked);
  };

  return (
    <Form.Check id={id}>
      <Form.Check.Input
        type="checkbox"
        className="me-3"
        defaultChecked={checkboxChecked}
        onChange={handleCheckBoxChangeEvent}
        onClick={(event: MouseEvent<HTMLInputElement>) =>
          event.stopPropagation()
        }
      />
      <Form.Check.Label
        className="d-flex"
        dir="auto"
        onClick={(event) => event.stopPropagation()}
      >
        <div>
          {checkboxChecked ? (
            <del>
              <Title text={label} />
            </del>
          ) : (
            <Title text={label} />
          )}
        </div>
      </Form.Check.Label>
    </Form.Check>
  );
};
