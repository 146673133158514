interface DividerArgs {
  text?: string;
  className?: string;
}

export const Divider = ({ text, className }: DividerArgs) => {
  if (text) {
    return (
      <div className="d-flex my-4">
        <div className="flex-grow-1">
          <hr className={`my-4 content-divider ${className}`} />
        </div>
        <div className="d-flex text-info align-items-center justify-content-center mx-2 px-0">
          {text}
        </div>
        <div className="flex-grow-1">
          <hr className={`my-4 content-divider ${className}`} />
        </div>
      </div>
    );
  }
  return <hr className={`content-divider ${className}`} />;
};
