import { useState } from "react";
import { ReactComponent as SmileyIcon } from "../assets/icons/smiley.svg";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import { Message } from "../types/Message";
import useNotification from "../hooks/useNotification";

const AutoHidingNotification = ({ message, variant }: Message) => {
  const [show, setShow] = useState(true);
  const icon = <SmileyIcon className="smiley-icon me-2" />;

  return (
    <Toast
      onClose={() => {
        setShow(false);
      }}
      bg={variant}
      show={show}
      autohide
      delay={5000}
    >
      <Toast.Header
        className={`bg-${variant} py-3 me-2 d-flex justify-content-between`}
      >
        <span>
          {icon} {message}
        </span>
      </Toast.Header>
    </Toast>
  );
};

export const ToastNotification = () => {
  const { messages } = useNotification();

  return (
    <ToastContainer className="pt-5 mt-4 me-2 z-index-1090" position="top-end">
      {messages?.map(({ variant, message }, index) => (
        <AutoHidingNotification
          key={index}
          message={message}
          variant={variant}
        />
      ))}
    </ToastContainer>
  );
};
