import { DisplayUser } from "../types/User";

declare interface MiniAvatarArgs {
  user: DisplayUser | null;
  useBorder?: boolean;
  className?: string;
}

const getColorIndexFromString = (str: string): number => {
  let hash = 0;
  const totalColor = 5;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  if (hash < 0) {
    hash *= -1;
  }
  return hash % totalColor;
};

const getInitials = (username: string, display_name?: string): string => {
  if (!display_name) {
    return username.slice(0, 2);
  }
  const bits = display_name.trim().split(" ");

  if (bits.length === 1) {
    return bits[0].slice(0, 2);
  }
  return bits[0].charAt(0) + bits[bits.length - 1].charAt(0);
};

export const MiniAvatar = ({ user, useBorder, className }: MiniAvatarArgs) => {
  const abb = !!user ? getInitials(user.username, user.display_name) : "";
  const colorIndex = getColorIndexFromString(`${user!.username}`);
  return (
    <div className={`mini-avatar ${className}`}>
      <div
        className={`d-block ${
          useBorder ? "use-border" : ""
        } color-${colorIndex}`}
      >
        <span className="noselect fw-bold">{abb.toUpperCase()}</span>
      </div>
    </div>
  );
};
