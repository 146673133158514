import { ChangeEvent, useRef, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { ReactComponent as SearchIcon } from "../assets/icons/magnifying-glass.svg";
import { ReactComponent as CrossIcon } from "../assets/icons/cross.svg";

declare interface SearchBarArgs {
  id: string;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  onChange: (newValue: string) => void;
  defaultValue?: string;
}

export const SearchBar = ({
  id,
  placeholder,
  disabled,
  className,
  onChange,
  defaultValue,
}: SearchBarArgs) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState<string>(defaultValue || "");
  const onValueChange = (value: string) => {
    setValue(value);
    onChange(value);
  };
  return (
    <InputGroup
      className={`search-bar px-0 position-relative ${
        className ? className : ""
      }`}
    >
      <InputGroup.Text className="px-3">
        <SearchIcon className="base-icon" />
      </InputGroup.Text>
      <Form.Control
        ref={inputRef}
        type="text"
        name="search-team"
        disabled={disabled}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          onValueChange(event.target.value)
        }
        id={id}
        placeholder={placeholder || `Search`}
        value={value}
      />
      <InputGroup.Text
        onClick={() => {
          onValueChange("");
        }}
        className="border-start-0 close px-3"
      >
        {value.length > 0 && (
          <CrossIcon className="white path position-absolute base-icon top-50 translate-middle-y" />
        )}
      </InputGroup.Text>
    </InputGroup>
  );
};
