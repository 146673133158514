import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import { MARKDOWNGUIDE_URL } from "../../constants/api-urls";
import { InterfaceComponentProps } from "../../types/Interaction";
import components from "../ReactMarkdownOverrides";

export const MarkdownTextarea = ({
  customizationArgs,
  response,
}: InterfaceComponentProps) => {
  const { t } = useTranslation();
  let placeholder = customizationArgs.placeholder;
  let [activeWriteTab, setActiveWriteTab] = useState(true);
  let [displayedValue, setDisplayedValue] = useState(
    response.model ? response.model.content : ""
  );

  let handleChanges = (event: any) => {
    setDisplayedValue(event.target.value);
    response.model = {
      content: event.target.value,
    };
  };

  return (
    <div className="d-flex flex-column markdown-editor-container">
      <div className="d-flex justify-content-between markdown-editor-navbar">
        <div className="d-flex">
          <button
            className={
              (activeWriteTab ? "markdown-editor-active-tab " : "") +
              "markdown-editor-tab"
            }
            type="button"
            onClick={() => {
              setActiveWriteTab(true);
            }}
          >
            {t("interactions.write")}
          </button>
          <button
            className={
              (activeWriteTab ? "" : "markdown-editor-active-tab ") +
              "markdown-editor-tab"
            }
            type="button"
            onClick={() => {
              setActiveWriteTab(false);
            }}
          >
            {t("interactions.preview")}
          </button>
        </div>
        <div className="markdown-editor-help-text">
          <Trans
            i18nKey={"interactions.markdownSupported"}
            components={[
              // Empty anchors will be rendered by i18n.Trans
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a href={MARKDOWNGUIDE_URL} target="_blank" rel="noreferrer" />,
            ]}
          />
        </div>
      </div>
      <div dir="auto">
        {activeWriteTab ? (
          <textarea
            dir="auto"
            rows={5}
            className="markdown-editor-textarea"
            placeholder={placeholder}
            value={displayedValue}
            onChange={handleChanges}
          ></textarea>
        ) : (
          <ReactMarkdown
            className="markdown-editor-preview"
            children={displayedValue}
            remarkPlugins={[remarkGfm]}
            components={components}
          />
        )}
      </div>
    </div>
  );
};
