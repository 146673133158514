import { useSingle } from "@opencraft/providence-redux/hooks";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Stack from "react-bootstrap/Stack";
import { DEFINITION_LIST_API } from "../../constants/api-urls";
import { InterfaceStats, TaskTrend, TrendsReport } from "../../types/Report";
import QuestionCard from "../../components/report_widgets/QuestionCard";
import ResponseRateChart from "../../components/report_widgets/ResponseRateChart";
import ProgressBar from "react-bootstrap/ProgressBar";
import RatingBarChart from "../../components/report_widgets/RatingBarChart";
import { LoadSection } from "../../components/LoadSection";
import DownloadButton from "../../components/report_widgets/DownloadButton";
import { useTranslation } from "react-i18next";

const ReportTrendsPage = () => {
  const { t } = useTranslation();
  const [currentQueryParameters] = useSearchParams();

  const controller = useSingle<TrendsReport>("trends-report", {});

  useEffect(() => {
    const currentChecklistDefinition = currentQueryParameters.get(
      "checklistDefinition"
    );
    const baseUrl = `${DEFINITION_LIST_API}${currentChecklistDefinition}/trends/`;
    const queryParams = currentQueryParameters.toString();
    if (queryParams && currentChecklistDefinition) {
      controller.endpoint = `${baseUrl}?${queryParams}`;
      controller.get();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQueryParameters]);

  const RatingChart = ({ trend }: { trend: TaskTrend }) => {
    const { customization_args } = trend.task_definition;
    const minValueDescription = customization_args["min_value_description"];
    const maxValueDescription = customization_args["max_value_description"];
    return (
      <Row>
        <Col style={{ height: "200px" }} md="7">
          <RatingBarChart
            interfaceStats={trend.interface_stats}
            minValueDescription={minValueDescription}
            maxValueDescription={maxValueDescription}
          />
        </Col>
        <Col
          className="text-white flex-row-reverse d-flex align-items-center"
          xs
          md={{ span: 2, offset: 3 }}
        >
          <div className="flex-column text-end">
            <p className="mb-1">
              Mean:{" "}
              <b className="fs-6">{trend.interface_stats.mean.toFixed(2)}</b>
            </p>
            <p className="mb-1">
              Median:{" "}
              <b className="fs-6">{trend.interface_stats.median.toFixed(2)}</b>
            </p>
            <p>
              Mode: <b className="fs-6">{trend.interface_stats.mode}</b>
            </p>
          </div>
        </Col>
      </Row>
    );
  };

  const Progress = ({ percentage }: { percentage: number }) => {
    return (
      <>
        <h6 className="text-end">{percentage}%</h6>
        <ProgressBar
          style={{ height: "24px", backgroundColor: "#4f5d76" }}
          variant="primary"
          now={percentage}
        />
      </>
    );
  };

  const NumericStats = ({
    interfaceStats,
  }: {
    interfaceStats: InterfaceStats;
  }) => {
    return (
      <Stack
        direction="horizontal"
        gap={5}
        className="text-white pt-3 pb-0 mb-0 flex-row d-flex"
      >
        <p>
          Mean: <b className="fs-6">{interfaceStats.mean.toFixed(2)}</b>
        </p>
        <p>
          Median: <b className="fs-6">{interfaceStats.median.toFixed(2)}</b>
        </p>
        <p>
          Mode: <b className="fs-6">{interfaceStats.mode}</b>
        </p>
      </Stack>
    );
  };

  const GenericQuestionTrend = ({ trend }: { trend: TaskTrend }) => {
    const { completed_count, total_count } = trend.response_rate;
    const answeredText = t("reports.trends.answerCountText", {
      completed_count,
      total_count,
    });
    const percentage = Math.round((completed_count / total_count) * 100);
    return (
      <QuestionCard
        question={trend.task_definition.label}
        subtitle={answeredText}
      >
        {trend.task_definition.interface_type === "linear_scale_rating" ? (
          <RatingChart trend={trend} />
        ) : trend.task_definition.interface_type === "numeric" ? (
          <NumericStats interfaceStats={trend.interface_stats} />
        ) : (
          <Progress percentage={percentage} />
        )}
      </QuestionCard>
    );
  };

  return (
    <LoadSection controllers={[controller]}>
      {() => (
        <>
          {!controller.x || controller.x.response_rates.length === 0 ? (
            <p className="text-center mt-5 pt-4 text-dark-slate">
              {t("reports.noResultsMsg")}
            </p>
          ) : (
            <>
              <DownloadButton
                baseUrl={controller.endpoint}
                name="trends-report"
              />
              <Stack className="mx-4 pt-4" gap={5}>
                <div>
                  <h6 className="text-white fw-bold mb-3">
                    {t("reports.trends.chartHeader")}
                  </h6>
                  <div style={{ height: "400px" }}>
                    <ResponseRateChart rates={controller.x?.response_rates} />
                  </div>
                </div>
                <div className="mt-5">
                  <h6 className="text-white fw-bold mb-3">
                    {t("reports.trends.tableHeader")}
                  </h6>
                  <Stack gap={4}>
                    {controller.x?.trends.map((trend) => (
                      <GenericQuestionTrend
                        key={trend.task_definition.id}
                        trend={trend}
                      />
                    ))}
                  </Stack>
                </div>
              </Stack>
            </>
          )}
        </>
      )}
    </LoadSection>
  );
};

export default ReportTrendsPage;
