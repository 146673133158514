import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";
import { ReactComponent as DownloadIcon } from "../../assets/icons/download.svg";
import { downloadFile } from "../../utils/helpers";

const DownloadButton = ({
  baseUrl,
  name,
}: {
  baseUrl: string;
  name: string;
}) => {
  const { t } = useTranslation();
  const download = (type: string) => {
    const downloadUrl = `${baseUrl}&report_format=${type}`;
    downloadFile(downloadUrl, `${name}.${type}`);
  };

  return (
    <Dropdown className="text-end download-small-button">
      <Dropdown.Toggle variant="outline" id="dropdown-autoclose-true">
        <DownloadIcon />
      </Dropdown.Toggle>

      <Dropdown.Menu className="fs-sm">
        <Dropdown.Item onClick={() => download("csv")}>
          {t("reports.download.csv")}
        </Dropdown.Item>
        <Dropdown.Item onClick={() => download("pdf")}>
          {t("reports.download.pdf")}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DownloadButton;
