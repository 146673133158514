import { useTranslation } from "react-i18next";
import { ChecklistStatus } from "../types/Task";
import Select, { MultiValue, Options } from "react-select";
import { SelectCheckboxDropdownItemOption } from "./react-select/SelectCheckboxDropdownItemOption";
import { SelectToggleDropdownIndicator } from "./react-select/SelectToggleDropdownIndicator";
import { SelectDropdownContainer } from "./react-select/SelectDropdownContainer";
import { dropdownSelectStyles } from "./react-select/styles";
import { SelectDropdownMenu } from "./react-select/SelectDropdownMenu";
import { SelectDropdownMenuList } from "./react-select/SelectDropdownMenuList";
import { useState } from "react";

export declare interface StatusFilterArgs {
  onChange: (values: MultiValue<StatusCustomOption>) => void;
  selectedStatuses: Array<string>;
  className?: string;
  isLoading?: boolean;
}

export interface StatusCustomOption {
  label: string;
  value: string;
}

const selectedStatusCountFunc = (
  value: Options<StatusCustomOption>
): number => {
  if (!value) {
    return 0;
  }

  if (!!value && !("length" in value)) {
    return 1;
  }
  const values: MultiValue<StatusCustomOption> =
    value as MultiValue<StatusCustomOption>;
  return values.length;
};

export const StatusFilter = ({
  className,
  onChange,
  selectedStatuses,
  isLoading,
}: StatusFilterArgs) => {
  const { t } = useTranslation();
  const options = Object.values(ChecklistStatus).map((status) => ({
    value: status,
    label: t(`checklistStatus.${status.toLowerCase()}`),
  }));
  const defaultValue = selectedStatuses
    .filter((statusValue) =>
      Object.values(ChecklistStatus).includes(statusValue as ChecklistStatus)
    )
    .map((statusValue) => ({
      label: t(`checklistStatus.${statusValue.toLowerCase()}`),
      value: statusValue,
    }));
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  return (
    <Select
      className={className}
      onChange={onChange}
      styles={dropdownSelectStyles}
      isDisabled={isLoading}
      components={{
        IndicatorSeparator: undefined,
        ClearIndicator: undefined,
        Option: SelectCheckboxDropdownItemOption,
        DropdownIndicator: SelectToggleDropdownIndicator({
          label: t("common.status"),
          countFunc: selectedStatusCountFunc,
        }),
        SelectContainer: SelectDropdownContainer({
          className: "filter",
          onDropdownToggle(nextValue: boolean) {
            setIsMenuOpen(nextValue);
          },
        }),
        Menu: SelectDropdownMenu,
        MenuList: SelectDropdownMenuList({ hasSearchBar: false }),
      }}
      value={selectedStatuses ? defaultValue : []}
      isMulti={true}
      options={options}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      isSearchable={false}
      tabSelectsValue={false}
      backspaceRemovesValue={false}
      blurInputOnSelect={false}
      controlShouldRenderValue={false}
      menuIsOpen={isMenuOpen}
    />
  );
};
