import { AreaChart, Area, XAxis, YAxis, ResponsiveContainer } from "recharts";
import { ResponseRate } from "../../types/Report";
import { dateString } from "../../utils/helpers";
import { useTranslation } from "react-i18next";

const ResponseRateChart = ({ rates }: { rates?: ResponseRate[] }) => {
  const { i18n } = useTranslation();
  const ltr = i18n.dir() === "ltr";
  let data =
    rates
      ?.filter((rate) => rate.total_count > 0)
      .map((rate) => {
        const percentage = Math.round(
          (rate.completed_count / rate.total_count) * 100
        );
        const label = dateString(rate.timestamp);
        return { percentage, label };
      }) || [];
  if (ltr) {
    data = data.reverse();
  }
  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        width={500}
        height={400}
        data={data}
        margin={{
          top: 10,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <XAxis
          dataKey="label"
          tickMargin={10}
          tickLine={false}
          style={{
            fontSize: "12px",
            color: "#fff",
          }}
          tick={{ stroke: "white" }}
        />
        <YAxis
          tickLine={false}
          style={{
            fontSize: "12px",
            color: "#fff",
          }}
          tick={{ stroke: "white" }}
          tickMargin={10}
          tickFormatter={(tick) => `${tick}%`}
          type="number"
          domain={[0, 100]}
          tickCount={7}
          orientation={ltr ? "left" : "right"}
          interval={"preserveStartEnd"}
        />
        <Area
          dataKey="percentage"
          stroke="#64ffda"
          strokeWidth={2}
          fill="#50ccae"
          fillOpacity={0.1}
          baseValue={0}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default ResponseRateChart;
