import { useSingle } from "@opencraft/providence-redux/hooks";
import { ProgressBar } from "react-bootstrap";
import { useMatch } from "react-router-dom";

export declare interface ProgressState {
  val: number;
}

export const ChecklistProgress = () => {
  const match = useMatch("/lists/:checklistId");

  const progressValue = useSingle<ProgressState>("taskProgress", {
    endpoint: "#",
    x: { val: 0 },
  });

  if (!match) {
    progressValue.p.val.model = 0;
    return <div className="mb-3" style={{ height: "3px" }} />;
  }

  return (
    <ProgressBar
      className="mb-3 sticky-top"
      variant="primary"
      now={progressValue.p.val.model}
    />
  );
};
