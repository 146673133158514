import Button from "react-bootstrap/Button";
import { InterfaceComponentProps } from "../../types/Interaction";

export const LinearScaleRate = ({
  customizationArgs,
  response,
}: InterfaceComponentProps) => {
  let end = customizationArgs.max_value!;
  let start = customizationArgs.min_value!;

  let items = Array(end - start + 1)
    .fill(null)
    .map((_, idx) => start + idx);

  return (
    <div className="listaflow-rating-interaction-container">
      <ul className="rating-number-list">
        {items.map((num) => {
          return (
            <li key={num.toString()}>
              <Button
                onClick={() => {
                  response.model = { rating: num };
                }}
                variant="rating-number-box"
                className={
                  response.model && response.model.rating === num
                    ? "active-rating-box"
                    : ""
                }
              >
                <bdi>{num}</bdi>
              </Button>
            </li>
          );
        })}
      </ul>
      <div className="rating-labels-container">
        <p className="rating-description-label">
          {customizationArgs.min_value_description}
        </p>
        <p className="rating-description-label">
          {customizationArgs.max_value_description}
        </p>
      </div>
    </div>
  );
};
