import { MATOMO_TAG_MANAGER_CONTAINER_URL } from "../constants/urls";

export const matamoTracking = () => {
  if (MATOMO_TAG_MANAGER_CONTAINER_URL !== "") {
    let _mtm = (window as any)._mtm || [];
    (window as any)._mtm = _mtm;
    _mtm.push({ "mtm.startTime": new Date().getTime(), event: "mtm.Start" });
    let g = document.createElement("script");
    let s = document.getElementsByTagName("script")[0];
    g.async = true;
    g.src = `${MATOMO_TAG_MANAGER_CONTAINER_URL}`;
    s.parentNode.insertBefore(g, s);
  }
};
