import { Dropdown, Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { GroupBase, MenuListProps, MultiValue } from "react-select";
import { Divider } from "../Divider";
import { SearchBar } from "../SearchBar";
import { cleanValue } from "./utils";

interface SelectDropdownMenuListArgs {
  hasSearchBar?: boolean;
}

export const SelectDropdownMenuList =
  ({ hasSearchBar }: SelectDropdownMenuListArgs) =>
  <Option, Group extends GroupBase<Option>>(
    props: MenuListProps<Option, true, Group>
  ) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { t } = useTranslation();
    const selectAll = () => {
      const previousValue: MultiValue<Option> =
        !props.selectProps.value || props.selectProps.value === null
          ? []
          : "length" in props.selectProps.value
          ? props.selectProps.value
          : [props.selectProps.value];
      const values: Array<Option> = [];
      const optionOrGroups = props.selectProps.options;
      for (const optionOrGroup of optionOrGroups) {
        if ("options" in optionOrGroup) {
          const group = optionOrGroup as Group;
          for (const option of group.options) {
            values.push(option);
          }
        } else {
          const option = optionOrGroup as Option;
          values.push(option);
        }
      }
      if (values.length === previousValue.length) {
        return;
      }
      props.selectProps.onChange(values, {
        action: "select-option",
        option: undefined,
      });
    };
    const deselectAll = () => {
      const selectedValue = cleanValue(props.selectProps.value);
      if (selectedValue.length) {
        props.selectProps.onChange([], {
          action: "clear",
          removedValues: selectedValue,
        });
      }
    };
    return (
      <>
        {hasSearchBar && (
          <Dropdown.ItemText className="px-0 pb-0 py-2">
            <SearchBar
              id="search-member"
              onChange={(value: string) =>
                props.selectProps.onInputChange(value, {
                  action: "input-change",
                  prevInputValue: props.selectProps.inputValue,
                })
              }
            />
            <Divider className="mb-0" />
          </Dropdown.ItemText>
        )}
        <Dropdown.ItemText className="d-flex px-0 pb-0 mb-n2 justify-content-end">
          <Nav.Link
            onClick={selectAll}
            className="text-uppercase d-inline-block fs-xs py-0 pe-0"
          >
            {t("common.select.all")}
          </Nav.Link>
          <Nav.Link
            onClick={deselectAll}
            className="text-uppercase d-inline-block fs-xs py-0 pe-0"
          >
            {t("common.select.none")}
          </Nav.Link>
        </Dropdown.ItemText>
        {props.children}
      </>
    );
  };
