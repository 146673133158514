import { ReactNode, useEffect, useState } from "react";
import { ListController } from "@opencraft/providence/base/lists/types/ListController";
import { LoadSection } from "./LoadSection";
import { useSearchParams } from "react-router-dom";
import Pagination from "react-bootstrap/Pagination";

declare interface PaginatedArgs<T> {
  children: ReactNode;
  controller: ListController<T>;
  hideTop?: boolean;
  hideBottom?: boolean;
}

declare interface PageNumbersArgs<T> {
  controller: ListController<T>;
}

const PageNumbers = <T,>({ controller }: PageNumbersArgs<T>) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page") || "1";
  const size = searchParams.get("size") || "24";
  const totalPages = controller.totalPages
    ? Math.ceil(controller.totalPages)
    : 1;

  const getPage = (pageNum: number) => {
    if (pageNum === controller.currentPage || pageNum > totalPages) {
      return;
    }
    controller.setPage(pageNum);
    controller.get().catch();
    const urlQueryParam = new URLSearchParams(searchParams);
    urlQueryParam.set("page", pageNum.toString());
    urlQueryParam.set("size", size);
    setSearchParams(urlQueryParam);
  };

  const [currentPage, setCurrentPage] = useState(parseInt(page));

  useEffect(() => {
    getPage(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  if (totalPages <= 1) {
    return <></>;
  }

  const pages: number[] = [];

  for (let i = 1; i <= totalPages; i++) {
    pages.push(i);
  }

  const getPrev = () => {
    if (controller.currentPage === 1) {
      return;
    }
    setCurrentPage(controller.currentPage - 1);
  };

  const getNext = () => {
    if (controller.currentPage === totalPages) {
      return;
    }
    setCurrentPage(controller.currentPage + 1);
  };

  return (
    <Pagination className="pagination justify-content-center">
      <Pagination.Prev onClick={getPrev} />
      {pages.map((index) => (
        <Pagination.Item
          key={index}
          active={controller.currentPage === index}
          onClick={() => setCurrentPage(index)}
        >
          {index}
        </Pagination.Item>
      ))}
      <Pagination.Next onClick={getNext} />
    </Pagination>
  );
};

export const Paginated = <T,>(props: PaginatedArgs<T>) => {
  return (
    <>
      <LoadSection controllers={[props.controller]}>
        {() => (
          <>
            {!props.hideTop && <PageNumbers controller={props.controller} />}
            <div className="row">{props.children}</div>
            {!props.hideBottom && <PageNumbers controller={props.controller} />}
          </>
        )}
      </LoadSection>
    </>
  );
};
