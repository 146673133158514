import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { InterfaceComponentProps } from "../../types/Interaction";
import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";

export const NumericInput = ({
  customizationArgs,
  response,
}: InterfaceComponentProps) => {
  const max = customizationArgs.max_value;
  const min = customizationArgs.min_value;
  let step = customizationArgs.step || 1;

  const [num, setNum] = useState<string>(response.model?.number || "");
  const errorClass = response.errors.length > 0 ? "error" : "";

  const { t } = useTranslation();

  let label = t("numericInput.baseLabel");

  if (max !== null && min === null) {
    label = t("numericInput.lessThanLabel", { max });
  } else if (min !== null && max === null) {
    label = t("numericInput.greaterThanLabel", { min });
  } else if (min !== null && max !== null) {
    label = t("numericInput.rangeLabel", { min, max });
  }

  const handleNumber = (e: ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    if (e.target.validity.valid) {
      setNum(input);
    }

    // Check whether input is number
    const parsedNumber = parseFloat(input);
    if (!isNaN(parsedNumber)) {
      response.model = { number: parsedNumber };
    }
  };

  const increment = () => {
    setNum((prev) => {
      let val: number;
      let current = parseFloat(prev);
      if (isNaN(current)) {
        val = min || 0;
      } else {
        // Handle floating point number inaccuracy issue
        // https://stackoverflow.com/questions/19290628/how-to-increment-number-by-0-01-in-javascript-using-a-loop
        val = parseFloat((current + step).toFixed(6));
      }
      if (max !== null && val > max) {
        val = current;
      }
      response.model = { number: val };
      return val.toString();
    });
  };

  const decrement = () => {
    setNum((prev) => {
      let val: number;
      let current = parseFloat(prev);
      if (isNaN(current)) {
        val = min || 0;
      } else {
        // Handle floating point number inaccuracy issue
        // https://stackoverflow.com/questions/19290628/how-to-increment-number-by-0-01-in-javascript-using-a-loop
        val = parseFloat((current - step).toFixed(6));
      }
      if (min !== null && val < min) {
        val = current;
      }
      response.model = { number: val };
      return val.toString();
    });
  };

  return (
    <Form.Group>
      <Form.Label className="text-info">
        <small>{label}</small>
      </Form.Label>
      <InputGroup className="numeric-input">
        <Button
          className="fs-4 py-0 px-3"
          variant="numeric"
          onClick={decrement}
          disabled={min !== null ? num <= min : false}
        >
          -
        </Button>
        <Form.Control
          className={`text-center p-0 ${errorClass}`}
          aria-label={label}
          type="text"
          min={min}
          max={max}
          step={step}
          placeholder="0"
          value={num}
          onChange={handleNumber}
          pattern="^-?[0-9]?\d*\.?\d*$"
          dir="auto"
        />
        <Button
          className="fs-4 py-0 px-3"
          variant="numeric"
          onClick={increment}
          disabled={max !== null ? num >= max : false}
        >
          +
        </Button>
      </InputGroup>
    </Form.Group>
  );
};
