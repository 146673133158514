import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {
  createStaticRanges,
  DateRangePicker,
  Range,
  RangeKeyDict,
  StaticRange,
} from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { dateString } from "../../utils/helpers";
import {
  addMonths,
  addYears,
  endOfMonth,
  endOfYear,
  isSameDay,
  parse,
  startOfMonth,
  startOfYear,
} from "date-fns";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as CalendarIcon } from "../../assets/icons/calendar.svg";
import { useTranslation } from "react-i18next";

interface PopupDateRangePickerArgs {
  onDateRangeChange: (startDate: Date, endDate: Date) => void;
  currentSelected: { startDate: string | null; endDate: string | null };
}

export const PopupDateRangePicker = ({
  onDateRangeChange: onChange,
  currentSelected,
}: PopupDateRangePickerArgs) => {
  const { t } = useTranslation();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const calendarRef = useRef<HTMLSpanElement>(null);
  const today = new Date();
  const monthStart = startOfMonth(today);
  const startDate =
    currentSelected.startDate &&
    parse(currentSelected.startDate, "yyyy-MM-dd", today);
  const endDate =
    currentSelected.endDate &&
    parse(currentSelected.endDate, "yyyy-MM-dd", today);
  const defaultRange = {
    startDate: startDate ? startDate : addMonths(today, -12),
    endDate: endDate ? endDate : today,
    key: "selection",
  };

  const [selectedDateRange, setSelectedDateRange] =
    useState<Range>(defaultRange);

  useEffect(() => {
    const { startDate, endDate } = defaultRange;
    const timer = setTimeout(() => {
      if (!currentSelected.startDate && !currentSelected.endDate) {
        onChange(startDate, endDate);
      }
    }, 500);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const staticRanges = createStaticRanges([
    // @ts-ignore
    {
      label: t("reports.dateRange.thisMonth"),
      range: () => ({
        startDate: monthStart,
        endDate: endOfMonth(today),
      }),
      hasCustomRendering: true,
    },
    // @ts-ignore
    {
      label: t("reports.dateRange.lastMonth"),
      range: () => ({
        startDate: addMonths(today, -1),
        endDate: today,
      }),
    },
    // @ts-ignore
    {
      label: t("reports.dateRange.last3Months"),
      range: () => ({
        startDate: addMonths(today, -3),
        endDate: today,
      }),
    },
    // @ts-ignore
    {
      label: t("reports.dateRange.last6Months"),
      range: () => ({
        startDate: addMonths(today, -6),
        endDate: today,
      }),
    },
    // @ts-ignore
    {
      label: t("reports.dateRange.last12Months"),
      range: () => ({
        startDate: addMonths(today, -12),
        endDate: today,
      }),
    },
    // @ts-ignore
    {
      label: t("reports.dateRange.thisYear"),
      range: () => ({
        startDate: startOfYear(today),
        endDate: endOfYear(today),
      }),
    },
    // @ts-ignore
    {
      label: t("reports.dateRange.lastYear"),
      range: () => ({
        startDate: startOfYear(addYears(today, -1)),
        endDate: endOfYear(addYears(today, -1)),
      }),
    },
    // @ts-ignore
    {
      label: t("reports.dateRange.last5Years"),
      range: () => ({
        startDate: addYears(today, -5),
        endDate: today,
      }),
    },
    // @ts-ignore
    {
      label: t("reports.dateRange.last10Years"),
      range: () => ({
        startDate: addYears(today, -10),
        endDate: today,
      }),
    },
  ]);

  const isSelected = (definedRange: Range, selected: Range) => {
    const { startDate, endDate } = selected;
    if (!startDate || !endDate) {
      return;
    }
    return (
      isSameDay(startDate, definedRange.startDate as Date) &&
      isSameDay(endDate, definedRange.endDate as Date)
    );
  };

  const setLabel = () => {
    if (!selectedDateRange) {
      return;
    }
    const selected = staticRanges.find((staticDefinedRange) => {
      const definedRange = staticDefinedRange.range();
      return isSelected(definedRange, selectedDateRange);
    });
    return (
      selected?.label ||
      `${dateString(selectedDateRange.startDate)} to ${dateString(
        selectedDateRange.endDate
      )}`
    );
  };

  const onRangeChange = (rangesByKey: RangeKeyDict) => {
    setSelectedDateRange(rangesByKey.selection);
    if (!rangesByKey.selection) {
      return;
    }
    const { startDate, endDate } = rangesByKey.selection;
    if (!startDate || !endDate) {
      return;
    }
    if (isSameDay(startDate, endDate)) {
      return;
    }
    onChange(startDate, endDate);
    document.body.click();
  };

  const nextMonthRange = () => {
    const { startDate, endDate } = selectedDateRange;
    if (!startDate || !endDate) {
      return;
    }
    const nextMonth = addMonths(startDate, 1);
    onRangeChange({
      selection: {
        startDate: startOfMonth(nextMonth),
        endDate: endOfMonth(nextMonth),
        key: "selection",
      },
    });
  };

  const prevMonthRange = () => {
    const { startDate, endDate } = selectedDateRange;
    if (!startDate || !endDate) {
      return;
    }
    const prevMonth = addMonths(startDate, -1);
    onRangeChange({
      selection: {
        startDate: startOfMonth(prevMonth),
        endDate: endOfMonth(prevMonth),
        key: "selection",
      },
    });
  };

  const onPopupClose = () => {
    if (buttonRef && buttonRef.current) {
      buttonRef.current.focus();
    }
  };

  const onPopupOpen = () => {
    if (calendarRef && calendarRef.current) {
      calendarRef.current.parentElement?.parentElement?.focus();
    }
  };

  const renderStaticRangeLabel = (staticRange: StaticRange) => (
    <span ref={calendarRef}>{staticRange.label}</span>
  );

  const popoverClickRootClose = (
    <Popover
      id="popover-trigger-click-root-close"
      placement="bottom-end"
      title={t("reports.dateRange.chooseDates")}
      style={{ maxWidth: "900px" }}
    >
      <DateRangePicker
        className="customCalendarWrapper"
        onChange={onRangeChange}
        showPreview={true}
        moveRangeOnFirstSelection={false}
        months={2}
        ranges={[selectedDateRange]}
        direction="horizontal"
        renderStaticRangeLabel={renderStaticRangeLabel}
        staticRanges={staticRanges}
        inputRanges={[]}
      />
    </Popover>
  );

  return (
    <ButtonGroup className="popup-date-range-button-group">
      <Button
        onClick={prevMonthRange}
        variant="outline"
        className="border-0 m-0 p-0 ps-1 fs-4"
      >
        {"<"}
      </Button>
      <OverlayTrigger
        trigger="click"
        rootClose
        onExit={onPopupClose}
        onEntered={onPopupOpen}
        placement="bottom-end"
        overlay={popoverClickRootClose}
      >
        <Button
          ref={buttonRef}
          variant="outline"
          className="d-flex align-items-center border-0 mx-1 fs-8 px-4 justify-content-center"
        >
          <CalendarIcon className="me-2" /> {setLabel()}
        </Button>
      </OverlayTrigger>
      <Button
        onClick={nextMonthRange}
        variant="outline"
        className="border-0 m-0 p-0 pe-1 fs-4"
      >
        {">"}
      </Button>
    </ButtonGroup>
  );
};
