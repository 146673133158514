import { useSingle } from "@opencraft/providence-redux/hooks";
import ReactCanvasconfetti from "react-canvas-confetti";

export declare interface ConfettiState {
  fire: boolean | number;
}

export const Confetti = () => {
  const confettiState = useSingle<ConfettiState>("confettiState", {
    endpoint: "#",
    x: { fire: false },
  });
  return (
    <ReactCanvasconfetti
      fire={confettiState.p.fire.model}
      className="confetti"
      origin={{ y: 0.35 }}
      spread={150}
      startVelocity={80}
      particleCount={100}
      ticks={100}
      gravity={1}
    />
  );
};
