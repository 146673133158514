import { useList } from "@opencraft/providence-redux/hooks";
import { useEffect, useState } from "react";
import Select, { MultiValue, Options } from "react-select";
import { DEFINITION_LIST_API } from "../../constants/api-urls";
import { Tag } from "../../types/Definition";
import { ReactComponent as FilterIcon } from "../../assets/icons/filter.svg";
import { SelectCheckboxDropdownItemOption } from "../react-select/SelectCheckboxDropdownItemOption";
import { SelectDropdownContainer } from "../react-select/SelectDropdownContainer";
import { SelectDropdownMenu } from "../react-select/SelectDropdownMenu";
import { SelectDropdownMenuList } from "../react-select/SelectDropdownMenuList";
import { SelectToggleDropdownIndicator } from "../react-select/SelectToggleDropdownIndicator";
import { dropdownSelectStyles } from "../react-select/styles";
import { useTranslation } from "react-i18next";

interface TagFilterArgs {
  selectedTags: Array<string>;
  checklistDefinition: string | null;
  onChange: (values: MultiValue<{ value: string; label: string }>) => void;
  isLoading?: boolean;
}

const selectedTagCountFunc = (
  value: Options<{ value: string; label: string }>
): number => {
  if (!value) {
    return 0;
  }

  if (!!value && !("length" in value)) {
    return 1;
  }
  const values: MultiValue<{ value: string; label: string }> =
    value as MultiValue<{ value: string; label: string }>;
  return values.length;
};

export const TagFilter = ({
  selectedTags,
  checklistDefinition,
  onChange,
  isLoading,
}: TagFilterArgs) => {
  const { t } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const checklistDefinitionId = checklistDefinition ? checklistDefinition : "";
  const tagsController = useList<Tag>([checklistDefinitionId, "related-tags"], {
    endpoint: `${DEFINITION_LIST_API}${checklistDefinition}/related_tags/`,
    paginated: false,
  });

  useEffect(() => {
    tagsController.get();
  }, [checklistDefinition, tagsController]);

  const options = tagsController.list.map((item) => {
    return { value: item.x!.name, label: item.x!.name };
  });

  const selectedRows = tagsController.list.filter((item) =>
    item.x ? selectedTags.includes(item.x.name) : false
  );

  const defaultValue = selectedRows.map((item) => {
    return {
      value: item?.x!.name,
      label: item?.x!.name,
    };
  });

  return (
    <Select
      isMulti
      className="mt-1"
      onChange={onChange}
      styles={dropdownSelectStyles}
      isDisabled={isLoading}
      noOptionsMessage={() => (
        <span className="fs-8 text-info">
          <br />
          {t("reports.filter.noOptionsMessage")}
        </span>
      )}
      components={{
        IndicatorSeparator: undefined,
        ClearIndicator: undefined,
        Option: SelectCheckboxDropdownItemOption,
        DropdownIndicator: SelectToggleDropdownIndicator({
          icon: (
            <FilterIcon
              className={`base-icon ${
                isMenuOpen ? "bright-turquoise" : "white"
              }`}
            />
          ),
          countFunc: selectedTagCountFunc,
        }),
        SelectContainer: SelectDropdownContainer({
          className: "filter scroll scrollbar light-scrollbar",
          onDropdownToggle(nextValue: boolean) {
            setIsMenuOpen(nextValue);
          },
        }),
        Menu: SelectDropdownMenu,
        MenuList: SelectDropdownMenuList({ hasSearchBar: true }),
      }}
      value={selectedTags ? defaultValue : []}
      options={options}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      isSearchable={false}
      tabSelectsValue={false}
      backspaceRemovesValue={false}
      blurInputOnSelect={false}
      controlShouldRenderValue={false}
      menuIsOpen={isMenuOpen}
    />
  );
};
