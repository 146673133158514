import { Dropdown, Form } from "react-bootstrap";
import { GroupBase, OptionProps } from "react-select";

export const SelectCheckboxDropdownItemOption = <
  Option,
  Group extends GroupBase<Option>
>(
  props: OptionProps<Option, true, Group>
) => {
  return (
    <Dropdown.ItemText
      className={`mx-0 my-2 px-2 py-1 check-item ${
        props.isSelected ? "checked" : ""
      }`}
      {...props.innerProps}
      ref={props.innerRef}
    >
      <Form.Check.Input
        className="me-2 sm"
        type="checkbox"
        checked={props.isSelected}
        onChange={(event: any) => event.stopPropagation()}
      />
      <Form.Check.Label className="d-inline-block text-sm">
        <small>{props.label}</small>
      </Form.Check.Label>
    </Dropdown.ItemText>
  );
};
