import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import BootstrapNavbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { ReactComponent as LogoutIcon } from "../assets/icons/log-out.svg";
import logo from "../assets/img/logo.svg";
import { ReactComponent as ProfileIcon } from "../assets/icons/profile.svg";
import { NavLink } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { useTranslation } from "react-i18next";
import * as urls from "../constants/urls";
import { CustomLink } from "./CustomNavLink";

export const Navbar = () => {
  const { auth, logoutUser } = useAuth();
  const { t } = useTranslation();
  return (
    <BootstrapNavbar collapseOnSelect expand="md" bg="secondary" variant="dark">
      <Container className="px-4" fluid>
        <BootstrapNavbar.Brand as={NavLink} to={urls.HOME_PAGE}>
          <img
            alt={t("navbar.logoAlt")}
            src={logo}
            height="40"
            className="d-inline-block align-top"
          />
        </BootstrapNavbar.Brand>
        <BootstrapNavbar.Toggle aria-controls="responsive-navbar-nav" />
        <BootstrapNavbar.Collapse id="responsive-navbar-nav">
          {auth?.access_token ? (
            <Nav className="ms-auto">
              <CustomLink className="mx-2" to={urls.USER_CHECKLISTS}>
                {t("navbar.mylist")}
              </CustomLink>
              <CustomLink className="mx-2" to={urls.TEMPLATES}>
                {t("navbar.templates")}
              </CustomLink>
              <CustomLink className="mx-2" to={urls.REPORTS_OVERVIEW}>
                {t("navbar.reports")}
              </CustomLink>
              <div className="vr nav-negative-margin"></div>
              <NavDropdown
                className="ps-3"
                align="end"
                title={<ProfileIcon className="base-icon" />}
                menuRole="menu"
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item
                  as={Button}
                  variant="navbar-dropdown"
                  onClick={logoutUser}
                >
                  <LogoutIcon className="base-icon" /> {t("navbar.logout")}
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          ) : (
            <Nav className="ms-auto">
              <CustomLink className="mx-2" to={urls.LOGIN}>
                {t("navbar.login")}
              </CustomLink>
              <CustomLink className="mx-2" to={urls.REGISTER}>
                {t("navbar.signup")}
              </CustomLink>
            </Nav>
          )}
        </BootstrapNavbar.Collapse>
      </Container>
    </BootstrapNavbar>
  );
};
